.l-wrapper{
	position: relative;
	min-height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	    padding-bottom: 384px;
	    @media(max-width: 1199px) {
	    	padding-bottom: 485px;
	    }
	   	@media (max-width: 767px){
	    	padding-bottom: 527px;
	    }
}

* {
	&:focus {
		outline: none!important;
		-webkit-box-shadow: none;
		box-shadow: none!important;
	}
}