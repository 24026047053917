.page-portfolio-filter {
	padding: 8rem 0 15rem;
	@media(max-width: $sw-xs-max) {
		padding: 3rem 0 5rem;
	}
	.container {
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-bottom: 1px solid #e2e2e2;
	}

	&__inner {
		padding: 0 15px;
	}


	.portfolio-item__wrap {
		margin-bottom: 90px;
		margin-right: 19.6px;
		margin-left: 19.6px;
		max-width: 350px;
		@media(max-width: $sw-md-max) {
			margin-bottom: 50px;
			margin-right: 18px;
			margin-left: 18px;
		}
		@media(max-width: $sw-sm-max) {
			margin-bottom: 40px;
			margin-right: 15.5px;
			margin-left: 15.5px;
		}
		@media(max-width: $sw-xs-max) {
			margin-bottom: 30px;
			margin-right: 2%;
			margin-left: 2%;
			width: 29%;
		}
	}

	.portfolio-contant {
		max-height: none;
	}
	

	.portfolio-item {
		width: 35rem;
		height: 35rem;
		@media(max-width: $sw-md-max) {
			width: 27rem;
			height: 26rem
		}
		@media(max-width: $sw-sm-max) {
			width: 20.92rem;
			height: 20rem
		}
		@media(max-width: $sw-xs-max) {
			width: 100%;
			height: 18rem;
			margin-bottom: 1.5rem;
		}
		@media(max-width: 600px) {
			width: 100%;
			height: 16rem;
		}

		@media(max-width: $sw-xx-max) {
			width: 100%;
			height: 12rem;
			margin-bottom: 1rem;
		}

		&--vr {
			height: 55rem;
			@media(max-width: $sw-md-max) {
				height: 42rem;
			}
			@media(max-width: $sw-sm-max) {
				height: 27.1rem;
			}
			@media(max-width: $sw-xs-max) {
				height: 25rem;
			}
			@media(max-width: 600px) {
				height: 24rem;
			}
			@media(max-width: $sw-xx-max) {
				height: 17rem;
			}
		}
	}

	.portfolio-item__descr {
		h3 {
			margin-bottom: 1rem;
			color: $black;
			font-size: 1.2rem;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 2.4px;
			@media(max-width: $sw-xx-max) {
				margin-bottom: 5px;
			}
		}
		h4 {
			color: $black;
			font-family: 'Comforta', sans-serif;
			font-size: 2.4rem;
			letter-spacing: 0.22px;
			font-weight: 300;
			a{
				color: #252525;
			}
			@media(max-width: $sw-sm-max) {
				font-size: 2.2rem;
			}
			@media(max-width: $sw-xs-max) {
				font-size: 2rem;
			}
			@media(max-width: $sw-xx-max) {
				font-size: 1.8rem;
			}
		}
	}

	.button {
		margin-right: auto;
		.icon-arrow-prev {
			transform: rotate(180deg);
			margin-left: 5px;
		}
	}
}