@media (min-width: 0) {
  .container {
    max-width: 100% !important; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px !important; } }

@media (min-width: 992px) {
  .container {
    max-width: 920px !important; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1200px !important; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(37, 37, 37, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #585858;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: underline;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #252525; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px; }
  @media (min-width: 768px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 960px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-xs-17, .col-xs-18, .col-xs-19, .col-xs-20, .col-xs-21, .col-xs-22, .col-xs-23, .col-xs-24, .col-xs,
.col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

@media (min-width: 480px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xs-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xs-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xs-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xs-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xs-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xs-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xs-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xs-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xs-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xs-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xs-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xs-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xs-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xs-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xs-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xs-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xs-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xs-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xs-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xs-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xs-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xs-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xs-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xs-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xs-first {
    order: -1; }
  .order-xs-last {
    order: 25; }
  .order-xs-0 {
    order: 0; }
  .order-xs-1 {
    order: 1; }
  .order-xs-2 {
    order: 2; }
  .order-xs-3 {
    order: 3; }
  .order-xs-4 {
    order: 4; }
  .order-xs-5 {
    order: 5; }
  .order-xs-6 {
    order: 6; }
  .order-xs-7 {
    order: 7; }
  .order-xs-8 {
    order: 8; }
  .order-xs-9 {
    order: 9; }
  .order-xs-10 {
    order: 10; }
  .order-xs-11 {
    order: 11; }
  .order-xs-12 {
    order: 12; }
  .order-xs-13 {
    order: 13; }
  .order-xs-14 {
    order: 14; }
  .order-xs-15 {
    order: 15; }
  .order-xs-16 {
    order: 16; }
  .order-xs-17 {
    order: 17; }
  .order-xs-18 {
    order: 18; }
  .order-xs-19 {
    order: 19; }
  .order-xs-20 {
    order: 20; }
  .order-xs-21 {
    order: 21; }
  .order-xs-22 {
    order: 22; }
  .order-xs-23 {
    order: 23; }
  .order-xs-24 {
    order: 24; }
  .offset-xs-0 {
    margin-left: 0; }
  .offset-xs-1 {
    margin-left: 4.16667%; }
  .offset-xs-2 {
    margin-left: 8.33333%; }
  .offset-xs-3 {
    margin-left: 12.5%; }
  .offset-xs-4 {
    margin-left: 16.66667%; }
  .offset-xs-5 {
    margin-left: 20.83333%; }
  .offset-xs-6 {
    margin-left: 25%; }
  .offset-xs-7 {
    margin-left: 29.16667%; }
  .offset-xs-8 {
    margin-left: 33.33333%; }
  .offset-xs-9 {
    margin-left: 37.5%; }
  .offset-xs-10 {
    margin-left: 41.66667%; }
  .offset-xs-11 {
    margin-left: 45.83333%; }
  .offset-xs-12 {
    margin-left: 50%; }
  .offset-xs-13 {
    margin-left: 54.16667%; }
  .offset-xs-14 {
    margin-left: 58.33333%; }
  .offset-xs-15 {
    margin-left: 62.5%; }
  .offset-xs-16 {
    margin-left: 66.66667%; }
  .offset-xs-17 {
    margin-left: 70.83333%; }
  .offset-xs-18 {
    margin-left: 75%; }
  .offset-xs-19 {
    margin-left: 79.16667%; }
  .offset-xs-20 {
    margin-left: 83.33333%; }
  .offset-xs-21 {
    margin-left: 87.5%; }
  .offset-xs-22 {
    margin-left: 91.66667%; }
  .offset-xs-23 {
    margin-left: 95.83333%; } }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 25; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 4.16667%; }
  .offset-sm-2 {
    margin-left: 8.33333%; }
  .offset-sm-3 {
    margin-left: 12.5%; }
  .offset-sm-4 {
    margin-left: 16.66667%; }
  .offset-sm-5 {
    margin-left: 20.83333%; }
  .offset-sm-6 {
    margin-left: 25%; }
  .offset-sm-7 {
    margin-left: 29.16667%; }
  .offset-sm-8 {
    margin-left: 33.33333%; }
  .offset-sm-9 {
    margin-left: 37.5%; }
  .offset-sm-10 {
    margin-left: 41.66667%; }
  .offset-sm-11 {
    margin-left: 45.83333%; }
  .offset-sm-12 {
    margin-left: 50%; }
  .offset-sm-13 {
    margin-left: 54.16667%; }
  .offset-sm-14 {
    margin-left: 58.33333%; }
  .offset-sm-15 {
    margin-left: 62.5%; }
  .offset-sm-16 {
    margin-left: 66.66667%; }
  .offset-sm-17 {
    margin-left: 70.83333%; }
  .offset-sm-18 {
    margin-left: 75%; }
  .offset-sm-19 {
    margin-left: 79.16667%; }
  .offset-sm-20 {
    margin-left: 83.33333%; }
  .offset-sm-21 {
    margin-left: 87.5%; }
  .offset-sm-22 {
    margin-left: 91.66667%; }
  .offset-sm-23 {
    margin-left: 95.83333%; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 25; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 4.16667%; }
  .offset-md-2 {
    margin-left: 8.33333%; }
  .offset-md-3 {
    margin-left: 12.5%; }
  .offset-md-4 {
    margin-left: 16.66667%; }
  .offset-md-5 {
    margin-left: 20.83333%; }
  .offset-md-6 {
    margin-left: 25%; }
  .offset-md-7 {
    margin-left: 29.16667%; }
  .offset-md-8 {
    margin-left: 33.33333%; }
  .offset-md-9 {
    margin-left: 37.5%; }
  .offset-md-10 {
    margin-left: 41.66667%; }
  .offset-md-11 {
    margin-left: 45.83333%; }
  .offset-md-12 {
    margin-left: 50%; }
  .offset-md-13 {
    margin-left: 54.16667%; }
  .offset-md-14 {
    margin-left: 58.33333%; }
  .offset-md-15 {
    margin-left: 62.5%; }
  .offset-md-16 {
    margin-left: 66.66667%; }
  .offset-md-17 {
    margin-left: 70.83333%; }
  .offset-md-18 {
    margin-left: 75%; }
  .offset-md-19 {
    margin-left: 79.16667%; }
  .offset-md-20 {
    margin-left: 83.33333%; }
  .offset-md-21 {
    margin-left: 87.5%; }
  .offset-md-22 {
    margin-left: 91.66667%; }
  .offset-md-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 25; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 4.16667%; }
  .offset-lg-2 {
    margin-left: 8.33333%; }
  .offset-lg-3 {
    margin-left: 12.5%; }
  .offset-lg-4 {
    margin-left: 16.66667%; }
  .offset-lg-5 {
    margin-left: 20.83333%; }
  .offset-lg-6 {
    margin-left: 25%; }
  .offset-lg-7 {
    margin-left: 29.16667%; }
  .offset-lg-8 {
    margin-left: 33.33333%; }
  .offset-lg-9 {
    margin-left: 37.5%; }
  .offset-lg-10 {
    margin-left: 41.66667%; }
  .offset-lg-11 {
    margin-left: 45.83333%; }
  .offset-lg-12 {
    margin-left: 50%; }
  .offset-lg-13 {
    margin-left: 54.16667%; }
  .offset-lg-14 {
    margin-left: 58.33333%; }
  .offset-lg-15 {
    margin-left: 62.5%; }
  .offset-lg-16 {
    margin-left: 66.66667%; }
  .offset-lg-17 {
    margin-left: 70.83333%; }
  .offset-lg-18 {
    margin-left: 75%; }
  .offset-lg-19 {
    margin-left: 79.16667%; }
  .offset-lg-20 {
    margin-left: 83.33333%; }
  .offset-lg-21 {
    margin-left: 87.5%; }
  .offset-lg-22 {
    margin-left: 91.66667%; }
  .offset-lg-23 {
    margin-left: 95.83333%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 767.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 768px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #585858;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ""; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1200px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      transition: none; } }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

@media (min-width: 480px) {
  .d-xs-none {
    display: none !important; }
  .d-xs-inline {
    display: inline !important; }
  .d-xs-inline-block {
    display: inline-block !important; }
  .d-xs-block {
    display: block !important; }
  .d-xs-table {
    display: table !important; }
  .d-xs-table-row {
    display: table-row !important; }
  .d-xs-table-cell {
    display: table-cell !important; }
  .d-xs-flex {
    display: flex !important; }
  .d-xs-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

@media (min-width: 480px) {
  .flex-xs-row {
    flex-direction: row !important; }
  .flex-xs-column {
    flex-direction: column !important; }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xs-wrap {
    flex-wrap: wrap !important; }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xs-fill {
    flex: 1 1 auto !important; }
  .flex-xs-grow-0 {
    flex-grow: 0 !important; }
  .flex-xs-grow-1 {
    flex-grow: 1 !important; }
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xs-start {
    justify-content: flex-start !important; }
  .justify-content-xs-end {
    justify-content: flex-end !important; }
  .justify-content-xs-center {
    justify-content: center !important; }
  .justify-content-xs-between {
    justify-content: space-between !important; }
  .justify-content-xs-around {
    justify-content: space-around !important; }
  .align-items-xs-start {
    align-items: flex-start !important; }
  .align-items-xs-end {
    align-items: flex-end !important; }
  .align-items-xs-center {
    align-items: center !important; }
  .align-items-xs-baseline {
    align-items: baseline !important; }
  .align-items-xs-stretch {
    align-items: stretch !important; }
  .align-content-xs-start {
    align-content: flex-start !important; }
  .align-content-xs-end {
    align-content: flex-end !important; }
  .align-content-xs-center {
    align-content: center !important; }
  .align-content-xs-between {
    align-content: space-between !important; }
  .align-content-xs-around {
    align-content: space-around !important; }
  .align-content-xs-stretch {
    align-content: stretch !important; }
  .align-self-xs-auto {
    align-self: auto !important; }
  .align-self-xs-start {
    align-self: flex-start !important; }
  .align-self-xs-end {
    align-self: flex-end !important; }
  .align-self-xs-center {
    align-self: center !important; }
  .align-self-xs-baseline {
    align-self: baseline !important; }
  .align-self-xs-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 480px) {
  .float-xs-left {
    float: left !important; }
  .float-xs-right {
    float: right !important; }
  .float-xs-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

@media (min-width: 480px) {
  .m-xs-0 {
    margin: 0 !important; }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important; }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important; }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important; }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important; }
  .m-xs-1 {
    margin: 0.25rem !important; }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important; }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important; }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important; }
  .m-xs-2 {
    margin: 0.5rem !important; }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important; }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important; }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important; }
  .m-xs-3 {
    margin: 1rem !important; }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem !important; }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem !important; }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem !important; }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem !important; }
  .m-xs-4 {
    margin: 1.5rem !important; }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.5rem !important; }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.5rem !important; }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.5rem !important; }
  .m-xs-5 {
    margin: 3rem !important; }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 3rem !important; }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 3rem !important; }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 3rem !important; }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 3rem !important; }
  .p-xs-0 {
    padding: 0 !important; }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important; }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important; }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important; }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important; }
  .p-xs-1 {
    padding: 0.25rem !important; }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important; }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important; }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important; }
  .p-xs-2 {
    padding: 0.5rem !important; }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important; }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important; }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important; }
  .p-xs-3 {
    padding: 1rem !important; }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem !important; }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem !important; }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem !important; }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem !important; }
  .p-xs-4 {
    padding: 1.5rem !important; }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.5rem !important; }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.5rem !important; }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.5rem !important; }
  .p-xs-5 {
    padding: 3rem !important; }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 3rem !important; }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 3rem !important; }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 3rem !important; }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 3rem !important; }
  .m-xs-auto {
    margin: auto !important; }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important; }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important; }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important; }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@media (min-width: 480px) {
  .text-xs-left {
    text-align: left !important; }
  .text-xs-right {
    text-align: right !important; }
  .text-xs-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #585858 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(37, 37, 37, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.h-reset-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0; }

html {
  height: 100%;
  font-size: 10px; }

body {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  letter-spacing: .015rem;
  font-family: 'Oswald', sans-serif; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

li {
  margin: 0;
  padding: 0;
  font-family: 'Roboto'; }

a {
  text-decoration: none; }

p {
  margin: 0;
  font-family: 'Roboto'; }

img {
  max-width: 100%;
  height: auto; }

.fadeInUp {
  animation-duration: 1s; }

.subtitle--black {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: #252525;
  letter-spacing: 4.8px; }
  @media (max-width: 479px) {
    .subtitle--black {
      font-size: 20px; } }

.subtitle--red {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: #b51622;
  letter-spacing: 4.8px; }
  @media (max-width: 479px) {
    .subtitle--red {
      font-size: 20px; } }

.subtitle--white {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 4.8px; }
  @media (max-width: 479px) {
    .subtitle--white {
      font-size: 20px; } }

.title-data {
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
  color: #b51622;
  font-weight: 500;
  letter-spacing: 2.72px; }
  @media (max-width: 479px) {
    .title-data {
      font-size: 1.2rem;
      letter-spacing: 2px; } }

.p-about-me__quote {
  margin: auto;
  padding: 4rem 0 1.3rem;
  max-width: 720px; }
  .p-about-me__quote .author {
    text-align: right; }
  .p-about-me__quote p {
    font-size: 16px;
    color: #252525;
    line-height: 2.6em;
    letter-spacing: 0.4px;
    text-align: center;
    font-style: italic; }

.p--bl, .tab-pane p, .services-slider__text p, .services-end-text p {
  margin-bottom: 3.5rem;
  font-size: 13px;
  color: #252525;
  line-height: 1.5em;
  letter-spacing: 0.4px;
  text-align: center;
  text-align: left; }
  @media (max-width: 767px) {
    .p--bl, .tab-pane p, .services-slider__text p, .services-end-text p {
      margin-bottom: 2rem; } }

.p--wh {
  margin-bottom: 3.5rem;
  font-size: 13px;
  color: #fff;
  line-height: 1.5em;
  letter-spacing: 0.4px;
  text-align: center;
  text-align: left; }
  @media (max-width: 767px) {
    .p--wh {
      margin-bottom: 2rem; } }

.p--it {
  font-size: 14px;
  font-style: italic;
  line-height: 2.1em;
  font-weight: 500; }

.set-up {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .set-up {
      align-items: center; } }
  @media (max-width: 767px) {
    .set-up {
      flex-direction: column;
      justify-content: center; } }
  .set-up__left {
    width: 60%; }
    @media (max-width: 767px) {
      .set-up__left {
        order: 2;
        width: 100%; }
        .set-up__left .subtitle--red {
          text-align: center; } }
    .set-up__left p {
      margin-bottom: 3.5rem;
      font-size: 13px;
      color: #252525;
      line-height: 1.5em;
      letter-spacing: 0.4px;
      text-align: center;
      text-align: left; }
      @media (max-width: 767px) {
        .set-up__left p {
          margin-bottom: 2rem; } }
  .set-up__right {
    width: 30%; }
    @media (max-width: 767px) {
      .set-up__right {
        order: 1;
        margin-bottom: 3rem;
        width: auto; } }
  .set-up p {
    font-size: 1.3rem;
    color: #252525;
    font-weight: 300; }

.service-form {
  padding-top: 5rem;
  border-bottom: 1px solid #b4b4b4; }
  .service-form .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .service-form .form-group:not(:nth-child(3)) {
    width: 47%; }
    @media (max-width: 479px) {
      .service-form .form-group:not(:nth-child(3)) {
        width: 100%; } }
  .service-form .form-group:nth-child(3) {
    width: 100%; }
  .service-form .form-control {
    border-bottom: 4px solid #e2e2e2;
    color: #252525; }
    .service-form .form-control::placeholder {
      color: #252525; }

.services-end-text {
  padding: 10rem 0; }
  @media (max-width: 767px) {
    .services-end-text {
      padding: 5rem 0; } }
  .services-end-text h2 {
    margin-bottom: 4rem; }
    @media (max-width: 767px) {
      .services-end-text h2 {
        margin-bottom: 1rem; } }
  .services-end-text .p--bl {
    margin-bottom: 3rem; }
    @media (max-width: 767px) {
      .services-end-text .p--bl {
        margin-bottom: 1rem; } }

.h-object-fit {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: block; }
  .h-object-fit > img {
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    height: auto;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    object-fit: cover;
    max-height: none;
    max-width: none;
    height: 100%; }
    .no-object-fit .h-object-fit > img,
    .no-objectfit .h-object-fit > img {
      display: none; }

@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald/regular/Oswald-Regular.eot");
  src: url("../fonts/oswald/regular/Oswald-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/oswald/regular/Oswald-Regular.woff") format("woff"), url("../fonts/oswald/regular/Oswald-Regular.ttf") format("truetype"), url("../fonts/oswald/regular/Oswald-Regular.svg#fontname") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald/medium/Oswald-Medium.eot");
  src: url("../fonts/oswald/medium/Oswald-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/oswald/medium/Oswald-Medium.woff") format("woff"), url("../fonts/oswald/medium/Oswald-Medium.ttf") format("truetype"), url("../fonts/oswald/medium/Oswald-Medium.svg#fontname") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald/bold/Oswald-Bold.eot");
  src: url("../fonts/oswald/bold/Oswald-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/oswald/bold/Oswald-Bold.woff") format("woff"), url("../fonts/oswald/bold/Oswald-Bold.ttf") format("truetype"), url("../fonts/oswald/bold/Oswald-Bold.svg#fontname") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Comforta";
  src: url("../fonts/comforta/light/Comfortaa-Light.eot");
  src: url("../fonts/comforta/light/Comfortaa-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/comforta/light/Comfortaa-Light.woff") format("woff"), url("../fonts/comforta/light/Comfortaa-Light.ttf") format("truetype"), url("../fonts/comforta/light/Comfortaa-Light.svg#fontname") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Comforta";
  src: url("../fonts/comforta/regular/Comfortaa-Regular.eot");
  src: url("../fonts/comforta/regular/Comfortaa-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/comforta/regular/Comfortaa-Regular.woff") format("woff"), url("../fonts/comforta/regular/Comfortaa-Regular.ttf") format("truetype"), url("../fonts/comforta/regular/Comfortaa-Regular.svg#fontname") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Comforta";
  src: url("../fonts/comforta/bold/Comfortaa-Bold.eot");
  src: url("../fonts/comforta/bold/Comfortaa-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/comforta/bold/Comfortaa-Bold.woff") format("woff"), url("../fonts/comforta/bold/Comfortaa-Bold.ttf") format("truetype"), url("../fonts/comforta/bold/Comfortaa-Bold.svg#fontname") format("svg");
  font-weight: 700;
  font-style: normal; }

.l-wrapper {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 384px; }
  @media (max-width: 1199px) {
    .l-wrapper {
      padding-bottom: 485px; } }
  @media (max-width: 767px) {
    .l-wrapper {
      padding-bottom: 527px; } }

*:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none !important; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }

/* ==========================================
  Single-colored icons can be modified like so:
  .icon-name {
    font-size: 32px;
    color: red;
  }
  ========================================== */
.icon-burger-menu {
  font-size: 1.4rem;
  color: #fff; }

.icon-search {
  font-size: 2.4rem;
  color: #252525; }

.icon-arrow-down {
  font-size: 7px; }

.icon-inst {
  color: #c5c5c5; }

.icon-pint {
  color: #c5c5c5; }

.icon-face {
  color: #c5c5c5; }

.icon-be {
  color: #c5c5c5; }

.icon-logo {
  width: 4.957547169811321em; }

button {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer; }

.btn {
  display: inline-block;
  border-radius: 11px;
  font-weight: 400;
  line-height: 48px;
  padding: 0 28px;
  border: 1px solid transparent;
  background-color: transparent;
  color: inherit;
  transition: all 300ms ease; }

.button {
  padding: 0 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.3rem;
  line-height: 5.3rem;
  color: #fffefe;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2.4px;
  background-color: #b51622;
  transition: all 0.3s ease; }
  @media (max-width: 767px) {
    .button {
      height: 3.5rem;
      padding: 0 1.5rem;
      line-height: 3.5rem; } }
  @media (max-width: 479px) {
    .button {
      padding: 0 1rem;
      font-size: 1rem; } }
  .button:hover {
    background-color: #252525;
    color: #fffefe; }
  .button--lg {
    width: 18rem; }
    @media (max-width: 767px) {
      .button--lg {
        width: 15rem; } }
  .button--black {
    background-color: #252525; }
    .button--black:hover {
      background-color: #b51622;
      color: #fffefe; }
  .button--white {
    background-color: #fff;
    color: #b51622; }
    .button--white:hover {
      background-color: #b51622;
      color: #fffefe; }

.form-control {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 4px solid rgba(255, 255, 255, 0.5);
  -webkit-appearance: none;
  color: #fffefe;
  font-family: 'Roboto';
  font-size: 1.4rem;
  padding-bottom: 1rem;
  height: auto; }
  .form-control::placeholder {
    transition: opacity 0.3s ease;
    opacity: 1;
    color: #fffefe;
    font-size: 1.4rem; }
  .form-control.error {
    border-color: #ff0000 !important;
    background-color: #ffdede; }
  .form-control:disabled .form-control, .form-control[disabled] .form-control, .form-control.disabled .form-control {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

.form-group:disabled .form-control, .form-group[disabled] .form-control, .form-group.disabled .form-control {
  pointer-events: none;
  cursor: default;
  opacity: 0.5; }

.input-wrap,
.select-wrap {
  position: relative;
  width: 100%; }

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%; }

.bootstrap-select > .dropdown-toggle.dropdown-toggle, .bootstrap-select > .dropdown-toggle.dropdown-toggle:hover, .bootstrap-select > .dropdown-toggle.dropdown-toggle:focus, .bootstrap-select > .dropdown-toggle.dropdown-toggle:active, .bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  outline: none !important; }

label {
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer; }
  label.error {
    color: #ff0000; }

.hidden-input {
  visibility: hidden !important;
  position: absolute !important;
  z-index: -1 !important;
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
  left: 0 !important;
  top: 0 !important; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.field_clear_btn {
  transition: color 0.3s ease;
  margin-bottom: auto;
  position: absolute;
  margin-top: auto;
  display: block;
  color: #d8d8d8;
  height: 30px;
  width: 30px;
  bottom: 0;
  right: 0;
  top: 0; }
  .field_clear_btn:hover {
    border-color: #b61f27;
    color: #b61f27; }
  .field_clear_btn:before, .field_clear_btn:after {
    background-color: currentColor;
    position: absolute;
    display: block;
    margin: auto;
    content: '';
    height: 2px;
    width: 16px;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0; }
  .field_clear_btn:before {
    transform: rotate(45deg); }
  .field_clear_btn:after {
    transform: rotate(-45deg); }

.checkbox-label {
  position: relative;
  padding-left: 30px; }
  .checkbox-label:disabled, .checkbox-label[disabled], .checkbox-label.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .checkbox-label .check-icon {
    border: solid 1px #979797;
    border-radius: 4px;
    position: absolute;
    display: block;
    height: 18px;
    width: 18px;
    left: 0;
    top: 2px; }
    .checkbox-label .check-icon:before {
      transform: rotate(-52.5deg) skew(-15deg);
      transition: opacity 0.3s ease;
      border-bottom: solid 3px #b61f27;
      border-left: solid 3px #b61f27;
      position: absolute;
      display: block;
      margin: auto;
      content: '';
      height: 9px;
      width: 15px;
      opacity: 0;
      top: -10px;
      left: 5px;
      bottom: 0;
      right: 0; }
  .checkbox-label .check-value {
    transition: color 0.3s ease;
    color: #323232; }
  .checkbox-label.label_grey .check-value {
    color: #979797; }
  .checkbox-label input:checked ~ .check-icon:before {
    opacity: 1; }
  .checkbox-label input:disabled ~ .check-icon {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .checkbox-label input:disabled ~ .check-value {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

.radio-label {
  padding-left: 20px;
  position: relative; }
  .radio-label:disabled, .radio-label[disabled], .radio-label.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .radio-label .check-icon {
    border: solid 1px #0171a6;
    border-radius: 50%;
    position: absolute;
    display: block;
    height: 13px;
    width: 13px;
    top: 4px;
    left: 0; }
    .radio-label .check-icon:before {
      transition: opacity 0.3s ease;
      background-color: #0171a6;
      border-radius: 50%;
      position: absolute;
      display: block;
      margin: auto;
      content: '';
      height: 5px;
      width: 5px;
      opacity: 0;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0; }
  .radio-label .check-value {
    transition: color 0.3s ease;
    color: #979797; }
  .radio-label.label_grey .check-value {
    color: #979797; }
  .radio-label input:checked ~ .check-icon:before {
    opacity: 1; }
  .radio-label input:disabled ~ .check-icon {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .radio-label input:disabled ~ .check-value {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

.footer {
  bottom: 0;
  background-color: #2a2a2a;
  flex-shrink: 0;
  position: absolute;
  bottom: 0;
  width: 100%; }
  .footer-main {
    padding: 5.5rem 0 8rem;
    display: flex;
    align-items: stretch;
    max-width: 100%; }
    @media (max-width: 991px) {
      .footer-main {
        flex-wrap: wrap;
        padding: 4rem 0; } }
  .footer__menu {
    padding-right: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    text-transform: uppercase;
    border-right: 1px solid #c5c5c5; }
    @media (max-width: 1199px) {
      .footer__menu {
        padding-right: 10rem; } }
    @media (max-width: 991px) {
      .footer__menu {
        order: 2;
        width: 50%;
        text-align: right; } }
    @media (max-width: 767px) {
      .footer__menu {
        padding-right: 3rem; } }
    @media (max-width: 479px) {
      .footer__menu {
        padding-right: 3rem; } }
    .footer__menu span {
      font-size: 1.8rem;
      font-weight: 700;
      color: #fffefe;
      letter-spacing: 3.6px;
      font-family: 'Oswald', sans-serif; }
    .footer__menu-items {
      font-size: 1.5rem; }
      .footer__menu-items a {
        display: block;
        height: 100%;
        color: #c5c5c5;
        transition: color 0.3s ease; }
        .footer__menu-items a:hover, .footer__menu-items a.active, .footer__menu-items a:focus {
          color: #b51622; }
    .footer__menu-items:first-child {
      margin-top: 1rem; }
  .footer__services {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 12rem;
    border-right: 1px solid #c5c5c5; }
    @media (max-width: 1199px) {
      .footer__services {
        padding: 0 10rem; } }
    @media (max-width: 991px) {
      .footer__services {
        order: 3;
        border: none;
        padding-right: 0;
        width: 50%; } }
    @media (max-width: 767px) {
      .footer__services {
        padding-left: 3rem; } }
    @media (max-width: 479px) {
      .footer__services {
        padding-left: 3rem; } }
    .footer__services span {
      font-size: 1.8rem;
      font-weight: 700;
      color: #fffefe;
      letter-spacing: 3.6px;
      text-transform: uppercase;
      font-family: 'Oswald', sans-serif; }
    .footer__services-items {
      text-transform: capitalize;
      font-size: 1.5rem;
      line-height: 1.75em; }
      .footer__services-items a {
        display: block;
        height: 100%;
        color: #c5c5c5;
        color: #c5c5c5;
        transition: color 0.3s ease; }
        .footer__services-items a:hover, .footer__services-items a:focus {
          color: #b51622; }
    .footer__services-items:first-child {
      margin-top: 1rem; }
  .footer .footer__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 10rem;
    width: 43%; }
    @media (max-width: 1199px) {
      .footer .footer__info {
        padding-left: 5rem; } }
    @media (max-width: 991px) {
      .footer .footer__info {
        align-items: center;
        order: 1;
        padding: 0;
        width: 100%; } }
    @media (max-width: 767px) {
      .footer .footer__info {
        margin-bottom: 3rem; } }
    @media (max-width: 1199px) {
      .footer .footer__info .logo--sm {
        margin-bottom: 1rem; } }
    .footer .footer__info .icon-logo {
      font-size: 5.1rem;
      color: #c5c5c5; }
      @media (max-width: 1199px) {
        .footer .footer__info .icon-logo {
          font-size: 4.1rem; } }
    .footer .footer__info .footer__info-descr {
      font-size: 1.4rem;
      color: #c5c5c5;
      line-height: 22px;
      width: 100%; }
      @media (max-width: 1199px) {
        .footer .footer__info .footer__info-descr {
          margin-bottom: 1rem; } }
      @media (max-width: 991px) {
        .footer .footer__info .footer__info-descr {
          margin: 2rem 0; } }
      @media (max-width: 767px) {
        .footer .footer__info .footer__info-descr {
          margin-bottom: 1rem; } }
  .footer .social {
    display: flex; }
    .footer .social p {
      font-size: 1.4rem;
      color: #c5c5c5; }
    .footer .social-box a {
      margin-left: 1.5rem; }
    .footer .social-box .icon {
      font-size: 1.8rem;
      transition: color 0.3s ease; }
      .footer .social-box .icon:hover {
        color: #b51622; }
  .footer__copyrights {
    border-top: 1px solid #4c4c4c; }
    .footer__copyrights .container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 6.5rem;
      font-size: 1.3rem;
      color: #c5c5c5; }
      @media (max-width: 767px) {
        .footer__copyrights .container {
          font-size: 1.2rem; } }
      @media (max-width: 479px) {
        .footer__copyrights .container p {
          max-width: 60%;
          text-align: center; } }
    .footer__copyrights .button-top {
      position: absolute;
      top: 0;
      right: 1.5rem;
      width: 4.6rem;
      height: 4.6rem;
      background-color: #b51622;
      display: flex;
      justify-content: center;
      align-items: center; }
      .footer__copyrights .button-top .icon {
        color: #fff;
        font-size: 1.2rem;
        transform: rotate(90deg); }

.c-header {
  position: relative;
  z-index: 2;
  transition: background-color 0.5s ease; }
  .c-header .container {
    max-width: 1524px !important; }
  .c-header.fixed {
    background-color: #fff;
    position: fixed;
    z-index: 5;
    right: 0;
    left: 0;
    top: 0;
    border-bottom: 1px solid #e2e2e2; }
    .c-header.fixed ~ main {
      padding-top: 10.4rem; }
    .c-header.fixed .header__wrapper {
      border-bottom: none; }

.header__wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 0;
  border-bottom: 1px solid #e2e2e2; }
  @media (max-width: 767px) {
    .header__wrapper {
      padding: 1.5rem 0; } }

.burger-menu button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.7rem;
  height: 5.3rem;
  background-color: #252525;
  transition: background-color 0.3s ease; }
  @media (max-width: 767px) {
    .burger-menu button {
      width: 3.5rem;
      height: 3.5rem; } }
  .burger-menu button:hover {
    background-color: #b51622; }

.logo {
  margin-left: 18.5rem; }
  .logo a {
    display: block; }
  .logo .icon {
    font-size: 5.1rem;
    color: #252525; }
    @media (max-width: 767px) {
      .logo .icon {
        font-size: 3.1rem; } }
    @media (max-width: 479px) {
      .logo .icon {
        font-size: 2.8rem; } }
  @media (max-width: 991px) {
    .logo {
      margin-left: 4rem; } }
  @media (max-width: 767px) {
    .logo {
      margin-left: 4rem; } }
  @media (max-width: 479px) {
    .logo {
      margin-left: 0; } }
  @media (max-width: 1199px) {
    .logo-active {
      visibility: hidden; } }

.header__control {
  text-align: center;
  display: flex;
  align-items: stretch; }
  .header__control .button-contact {
    margin-right: 1.6rem; }
    @media (max-width: 479px) {
      .header__control .button-contact {
        margin-right: 5px;
        line-height: 1.3em;
        max-width: 7rem; } }
  .header__control .active .input-search {
    right: 4rem;
    opacity: 1;
    pointer-events: auto; }
    @media (max-width: 1199px) {
      .header__control .active .input-search {
        right: 5rem;
        width: 53rem; } }
    @media (max-width: 991px) {
      .header__control .active .input-search {
        width: 42.3rem; } }
    @media (max-width: 810px) {
      .header__control .active .input-search {
        width: 38rem; } }
    @media (max-width: 767px) {
      .header__control .active .input-search {
        right: 3rem;
        width: 32rem;
        min-width: auto;
        height: 3.5rem; } }
    @media (max-width: 630px) {
      .header__control .active .input-search {
        width: 24rem; } }
    @media (max-width: 610px) {
      .header__control .active .input-search {
        width: 22rem; } }
    @media (max-width: 530px) {
      .header__control .active .input-search {
        width: 20rem; } }
    @media (max-width: 515px) {
      .header__control .active .input-search {
        width: 18rem; } }
    @media (max-width: 479px) {
      .header__control .active .input-search {
        width: 22rem; } }
    @media (max-width: 400px) {
      .header__control .active .input-search {
        width: 19rem; } }
    @media (max-width: 400px) {
      .header__control .active .input-search {
        width: 17rem; } }
    @media (max-width: 350px) {
      .header__control .active .input-search {
        width: 14.2rem; } }

.search-form {
  position: relative;
  margin-right: 2.5rem;
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .search-form {
      margin-right: 1.5rem; } }
  @media (max-width: 479px) {
    .search-form {
      margin-right: 5px; } }
  .search-form .input-search {
    position: absolute;
    top: 0;
    right: -3rem;
    z-index: 7;
    padding: 0 5px;
    height: 5.3rem;
    min-width: 20rem;
    border: none;
    border-bottom: 3px solid #b51622;
    transition: all 0.3s ease;
    opacity: 0;
    pointer-events: none;
    font-family: 'Roboto';
    font-size: 1.5rem;
    background-color: transparent;
    border-radius: 0; }
    @media (max-width: 1199px) {
      .search-form .input-search {
        right: -10rem; } }
    @media (max-width: 767px) {
      .search-form .input-search {
        min-width: 18rem; } }
  .search-form .btn-submit {
    position: absolute;
    right: 0;
    display: none;
    width: 2.5rem;
    height: 100%; }
  .search-form .icon-search {
    cursor: pointer; }
    @media (max-width: 479px) {
      .search-form .icon-search {
        font-size: 2rem; } }

.search-form.active .btn-submit {
  display: block; }

.wrapper-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 3.3rem;
  color: #252525;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 1.5px; }
  @media (max-width: 479px) {
    .wrapper-dropdown {
      display: none; } }

.wrapper-dropdown .dropdown {
  position: absolute;
  top: 80%;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: normal;
  opacity: 0;
  pointer-events: none;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left; }
  .wrapper-dropdown .dropdown li a {
    display: block;
    text-decoration: none;
    color: #252525;
    font-weight: 500;
    height: 100%;
    transition: color 0.3s ease; }
  .wrapper-dropdown .dropdown li:hover a, .wrapper-dropdown .dropdown li.active a {
    color: #b51622; }

.wrapper-dropdown .icon-arrow-down {
  transition: all 0.3s ease; }

/* Active state */
.wrapper-dropdown.active .dropdown {
  opacity: 1;
  pointer-events: auto; }

.wrapper-dropdown.active .icon-arrow-down {
  transform: rotate(180deg); }

.main-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 20%;
  height: 0;
  overflow: hidden;
  transition: height .8s ease; }
  .main-menu:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #b51622;
    opacity: 0.9; }
  .main-menu.active {
    display: flex;
    justify-content: center;
    height: 100vh;
    transition: height 0.8s ease; }
  .main-menu-inner {
    position: relative;
    padding-top: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
  .main-menu .logo {
    margin-bottom: 13rem;
    margin-left: 5px; }
    @media (max-width: 991px) {
      .main-menu .logo {
        margin-left: -13.7rem; } }
    @media (max-width: 767px) {
      .main-menu .logo {
        padding-top: 0; } }
    @media (max-width: 479px) {
      .main-menu .logo {
        margin-left: -6.7rem; } }
    @media (max-width: 767px) {
      .main-menu .logo a {
        line-height: 1.5em; } }
    .main-menu .logo .icon {
      color: #fff; }
  .main-menu .btn-close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: #fff;
    font-size: 1.4rem; }
  .main-menu li {
    width: 45rem; }
    @media (max-width: 767px) {
      .main-menu li {
        width: auto; } }
  .main-menu a {
    position: relative;
    display: block;
    width: 100%;
    text-transform: uppercase;
    color: #fffefe;
    font-size: 4rem;
    font-weight: 500;
    line-height: 2em;
    transition: color 0.8s ease; }
    @media (max-width: 767px) {
      .main-menu a {
        font-size: 3rem; } }
    .main-menu a:hover {
      color: #252525; }
      @media (min-width: 768px) {
        .main-menu a:hover span {
          transform: translateX(31.5rem); }
          .main-menu a:hover span:before {
            transform: scaleX(1); } }
    .main-menu a span {
      position: relative;
      display: inline-block;
      transition: transform 0.8s ease; }
      .main-menu a span:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        display: block;
        width: 31.5rem;
        height: 3px;
        transform-origin: 100% 50%;
        transform: scaleX(0);
        background-color: #252525;
        transition: transform 0.8s ease; }

.last-post {
  padding: 18rem 0 16.5rem;
  background-color: #fff; }
  @media (max-width: 1199px) {
    .last-post {
      padding: 12rem 0 6.5rem; } }
  @media (max-width: 767px) {
    .last-post {
      padding: 5rem 0 8rem; } }
  .last-post__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media (max-width: 767px) {
      .last-post__info {
        margin-bottom: 4rem; } }
    .last-post__info-slider .slick-list {
      overflow: hidden; }
    .last-post__info-slider-item {
      display: inline-block; }
    .last-post__info-title {
      color: #252525;
      font-size: 4rem;
      font-weight: 700;
      letter-spacing: 0.9px;
      font-family: 'Comforta', sans-serif; }
    .last-post__info-title-descr {
      margin-bottom: 2rem;
      font-size: 1.4rem;
      color: #252525; }
    .last-post__info-subtitle {
      margin-bottom: 1rem;
      font-size: 2rem;
      color: #b51622;
      font-weight: 500;
      line-height: 1.4em;
      letter-spacing: 4px;
      width: 100%;
      transition: color 0.3s ease; }
      .last-post__info-subtitle:hover {
        color: #252525; }
    .last-post__info-date {
      margin-bottom: 2.2rem;
      font-size: 1.6rem;
      color: #252525;
      letter-spacing: -0.16px;
      font-family: 'Work Sans', sans-serif; }
    .last-post__info-descr {
      margin-bottom: 4.5rem;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #252525;
      width: 100%; }
      @media (max-width: 767px) {
        .last-post__info-descr {
          margin-bottom: 1.5rem; } }
  .last-post__slider-wrap {
    position: relative;
    padding-left: 5.7rem; }
    @media (max-width: 1199px) {
      .last-post__slider-wrap {
        padding-left: 0; } }
    .last-post__slider-wrap .slick-arrow-wrap {
      top: 0;
      left: 0; }
      @media (max-width: 1199px) {
        .last-post__slider-wrap .slick-arrow-wrap {
          top: auto;
          bottom: -5.3rem;
          left: auto;
          right: 0;
          display: flex; } }
      @media (max-width: 767px) {
        .last-post__slider-wrap .slick-arrow-wrap {
          top: -5.3rem;
          left: auto;
          right: 0; } }
      @media (max-width: 1199px) {
        .last-post__slider-wrap .slick-arrow-wrap .arrow-next {
          margin-top: 0;
          margin-left: 1px; } }
    .last-post__slider-wrap .slick-list {
      overflow: hidden; }
  .last-post__slide {
    display: inline-block; }
    @media (max-width: 767px) {
      .last-post__slide img {
        width: 100%; } }

.slick-arrow-wrap {
  position: absolute;
  z-index: 2; }
  .slick-arrow-wrap .arrow-prev,
  .slick-arrow-wrap .arrow-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5.7rem;
    height: 5.3rem;
    color: #fff;
    font-size: 1.2rem;
    background-color: #b51622;
    cursor: pointer;
    transition: background-color 0.3s ease; }
    .slick-arrow-wrap .arrow-prev:hover, .slick-arrow-wrap .arrow-prev:focus,
    .slick-arrow-wrap .arrow-next:hover,
    .slick-arrow-wrap .arrow-next:focus {
      background-color: #252525; }
  .slick-arrow-wrap .arrow-next {
    margin-top: 1px;
    transform: rotate(180deg); }

.main-banner {
  position: relative;
  top: -10rem;
  z-index: 0; }
  .main-banner:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: -20%;
    left: 0;
    width: 100%;
    height: 130%;
    display: block;
    background-image: url("../images/useful/bg/top-bg.png");
    background-size: cover; }
  @media (max-width: 767px) {
    .main-banner {
      padding-top: 5rem;
      /* height: 100vh; */
      height: auto;
      margin-bottom: -18rem; } }
  .main-banner__slider-item {
    display: inline-block; }
    .main-banner__slider-item:not(.slick-current) .container {
      opacity: 0;
      transition: opacity 1s; }
  .main-banner .container {
    position: relative;
    z-index: 1;
    padding-top: 33rem;
    padding-bottom: 22rem; }
    @media (max-width: 1199px) {
      .main-banner .container {
        padding-top: 16rem;
        padding-bottom: 16rem; } }
  .main-banner img {
    position: absolute;
    bottom: -4rem;
    right: 32%;
    min-width: 60%;
    height: 110%; }
    @media (max-width: 991px) {
      .main-banner img {
        right: 32%;
        height: 100%; } }
    @media (max-width: 767px) {
      .main-banner img {
        display: none; } }
    @media (max-width: 479px) {
      .main-banner img {
        max-width: inherit; } }

.main-banner__title-wrap {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 2; }

.main-banner__title-line {
  margin-right: 1.5rem;
  width: 23rem;
  height: 3rem;
  border-bottom: 3px solid #b51622;
  animation-delay: 4s;
  animation-duration: 2s; }

.main-banner__title-sm {
  font-family: 'Comforta', sans-serif;
  font-size: 2.4rem;
  font-weight: 300;
  color: #252525;
  animation-delay: 2s;
  animation-duration: 2s; }
  @media (max-width: 767px) {
    .main-banner__title-sm {
      font-size: 2.2rem; } }
  @media (max-width: 479px) {
    .main-banner__title-sm {
      font-size: 2rem; } }

.main-banner__title-xl {
  font-family: 'Comforta', sans-serif;
  font-size: 6.5rem;
  font-weight: 400;
  color: #252525;
  animation-delay: 3s;
  animation-duration: 2s; }
  @media (max-width: 767px) {
    .main-banner__title-xl {
      font-size: 5.4rem; } }
  @media (max-width: 479px) {
    .main-banner__title-xl {
      font-size: 4.4rem; } }

.work-direct {
  margin-top: -10rem;
  background-color: transparent; }
  @media (max-width: 767px) {
    .work-direct {
      margin-top: 0;
      margin-bottom: 5rem; } }
  .work-direct-wrap {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 1199px) {
      .work-direct-wrap {
        justify-content: flex-end; } }
  .work-direct__info-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (max-width: 1199px) {
      .work-direct__info-wrap {
        justify-content: flex-start; } }
    @media (max-width: 767px) {
      .work-direct__info-wrap {
        justify-content: space-between; } }
  .work-direct__info {
    padding-top: 20rem;
    width: 60%; }
    @media (max-width: 1199px) {
      .work-direct__info {
        padding-top: 15rem;
        margin-top: -5rem;
        width: 60%; } }
    @media (max-width: 991px) {
      .work-direct__info {
        padding-top: 10rem;
        margin-top: 0; } }
    @media (max-width: 767px) {
      .work-direct__info {
        padding-top: 5rem;
        margin-top: 0;
        width: 100%;
        order: 2; } }
    .work-direct__info .title--black {
      position: relative;
      margin-bottom: 5rem;
      display: inline-block; }
      @media (max-width: 767px) {
        .work-direct__info .title--black {
          margin-bottom: 3rem; } }
    .work-direct__info-item {
      width: 26%;
      margin-bottom: 5.5rem; }
      @media (max-width: 1199px) {
        .work-direct__info-item {
          width: 30%;
          margin-right: 3%;
          margin-bottom: 3.5rem; } }
      @media (max-width: 767px) {
        .work-direct__info-item {
          width: 45%; }
          .work-direct__info-item:nth-child(2) {
            margin-right: 0; } }
      @media (max-width: 479px) {
        .work-direct__info-item {
          margin-right: 0;
          margin-bottom: 3rem;
          width: 48%; } }
      .work-direct__info-item:hover {
        cursor: pointer; }
        .work-direct__info-item:hover .work-direct__info-img-wrap {
          border-color: transparent;
          background-color: #b51622; }
        .work-direct__info-item:hover .icon {
          color: #fff; }
      .work-direct__info-item h3 {
        margin-bottom: 1rem;
        color: #b51622;
        font-size: 1.5rem;
        font-family: 'Oswald', sans-serif;
        letter-spacing: 3px;
        text-transform: uppercase;
        font-weight: 500; }
      .work-direct__info-item p {
        font-size: 1.3rem;
        font-weight: 300;
        color: #252525; }
    .work-direct__info-img-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 3rem;
      width: 5.7rem;
      height: 5.7rem;
      border: 1px solid #252525;
      border-radius: 50%;
      transition: all 0.3s ease; }
      @media (max-width: 1199px) {
        .work-direct__info-img-wrap {
          margin-bottom: 2rem; } }
      @media (max-width: 479px) {
        .work-direct__info-img-wrap {
          margin-bottom: 2rem; } }
      .work-direct__info-img-wrap .icon {
        font-size: 3.5rem;
        color: #252525;
        transition: all 0.3s ease; }
  .work-direct__slider-wrap {
    position: relative;
    z-index: 1;
    padding-left: 5.7rem;
    width: 40%; }
    @media (max-width: 1199px) {
      .work-direct__slider-wrap {
        width: 40%; } }
    @media (max-width: 767px) {
      .work-direct__slider-wrap {
        width: 100%;
        order: 2; } }
    .work-direct__slider-wrap .slick-arrow-wrap {
      top: 0;
      left: 0; }
    .work-direct__slider-wrap img {
      width: 100%; }
    .work-direct__slider-wrap .button {
      margin-left: auto; }
  .work-direct__slider {
    margin-bottom: 3rem;
    overflow: hidden; }
  .work-direct__slide {
    display: inline-block; }

.title--black {
  font-size: 4rem;
  color: #252525;
  font-weight: 700;
  font-family: 'Comforta', sans-serif;
  text-transform: uppercase; }
  @media (max-width: 1199px) {
    .title--black {
      font-size: 3rem; } }
  @media (max-width: 479px) {
    .title--black {
      font-size: 2.8rem; } }

.title--white {
  position: relative;
  display: inline-block;
  font-size: 4rem;
  color: #fff;
  font-weight: 700;
  font-family: 'Comforta', sans-serif;
  text-transform: uppercase; }
  @media (max-width: 1199px) {
    .title--white {
      font-size: 3rem; } }
  @media (max-width: 479px) {
    .title--white {
      font-size: 2.8rem; } }

.line-after:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -10.4rem;
  transform: translateY(-50%);
  display: block;
  width: 9.4rem;
  height: 4px;
  background-color: #e4e4e4; }

.line-before:before {
  content: '';
  position: absolute;
  top: 50%;
  left: -10.4rem;
  transform: translateY(-50%);
  display: block;
  width: 9.4rem;
  height: 4px;
  background-color: #b51622; }

.about-me {
  padding-top: 10rem;
  background-color: #fff;
  position: relative; }
  @media (max-width: 1199px) {
    .about-me {
      padding-top: 3rem; } }
  @media (max-width: 479px) {
    .about-me {
      padding-top: 0; } }
  .about-me .about-me--left {
    position: absolute;
    bottom: 10rem;
    left: -34%;
    z-index: 3;
    width: 40%;
    height: 90%; }
    @media (max-width: 1199px) {
      .about-me .about-me--left {
        height: 70%;
        bottom: 3.8rem; } }
    @media (max-width: 991px) {
      .about-me .about-me--left {
        display: none; } }
  .about-me .about-me--bottom {
    position: absolute;
    left: 10%;
    bottom: 0;
    z-index: 2;
    width: 33%; }
    @media (max-width: 1199px) {
      .about-me .about-me--bottom {
        display: none; } }
  .about-me__wrap {
    position: relative;
    display: flex; }
    @media (max-width: 767px) {
      .about-me__wrap {
        flex-direction: column; } }
  .about-me__left {
    margin-right: 8rem;
    width: 60%;
    z-index: 2; }
    @media (max-width: 1199px) {
      .about-me__left {
        width: 60%;
        margin-right: 5rem; } }
    @media (max-width: 991px) {
      .about-me__left {
        width: 50%;
        margin-right: 3rem; } }
    @media (max-width: 767px) {
      .about-me__left {
        order: 2;
        width: 100%; } }
  .about-me__right {
    position: relative;
    z-index: 1;
    padding-left: 8rem;
    width: 40%; }
    @media (max-width: 1199px) {
      .about-me__right {
        padding-left: 5rem; } }
    @media (max-width: 991px) {
      .about-me__right {
        width: 50%;
        padding-left: 5rem; } }
    @media (max-width: 767px) {
      .about-me__right {
        margin-bottom: 3rem;
        padding-left: 0;
        width: 100%; } }
    .about-me__right:before {
      content: '';
      position: absolute;
      top: 0;
      right: -32rem;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-color: #313131; }
      @media (max-width: 767px) {
        .about-me__right:before {
          left: -5rem; } }
  .about-me__quote {
    margin-bottom: 3.6rem;
    display: flex; }
    @media (max-width: 991px) {
      .about-me__quote {
        flex-direction: column; } }
    @media (max-width: 991px) {
      .about-me__quote-text {
        order: 2;
        margin-top: 5rem; } }
    .about-me__quote-text p {
      margin-top: 2rem;
      margin-right: 6rem;
      font-size: 1.3rem;
      color: #252525;
      font-style: italic;
      font-weight: 300; }
      @media (max-width: 991px) {
        .about-me__quote-text p {
          margin-right: 1rem; } }
    .about-me__quote-author {
      width: 21.6rem;
      height: 21.6rem;
      flex-shrink: 0; }
      @media (max-width: 767px) {
        .about-me__quote-author {
          margin: auto; } }
      .about-me__quote-author img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
        width: 95%;
        margin: auto;
        border-radius: 50%; }
  .about-me__skills {
    position: relative;
    padding: 6rem 0 8rem; }
    .about-me__skills:before {
      content: '';
      position: absolute;
      top: 0;
      right: -11rem;
      bottom: 0;
      left: -37rem;
      background-color: #b51622; }
      @media (max-width: 1199px) {
        .about-me__skills:before {
          right: -8rem; } }
      @media (max-width: 991px) {
        .about-me__skills:before {
          right: -6rem; } }
    .about-me__skills h3 {
      position: relative;
      z-index: 2;
      font-size: 1.6rem;
      font-weight: 500;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 3px; }
    .about-me__skills li {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 2.8rem 0 1rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
      .about-me__skills li span {
        line-height: 1.7em;
        font-size: 1.4rem;
        color: #fff;
        letter-spacing: 0.35px; }
    .about-me__skills .creativity:after,
    .about-me__skills .problem-solving:after,
    .about-me__skills .detail-oriented:after,
    .about-me__skills .communication:after {
      content: '';
      position: absolute;
      bottom: -4px;
      height: 7px;
      left: 0;
      display: block;
      background-image: linear-gradient(to right, #de2432 0%, #ffffff 100%); }
    .about-me__skills .creativity:after {
      width: 85%;
      transform-origin: 0 50%;
      transition: transform 2s ease;
      transition-delay: 0.5s;
      transform: scaleX(0); }
    .about-me__skills .problem-solving:after {
      width: 50%;
      transform-origin: 0 50%;
      transition: transform 2s ease;
      transition-delay: 1s;
      transform: scaleX(0); }
    .about-me__skills .detail-oriented:after {
      width: 75%;
      transform-origin: 0 50%;
      transition: transform 2s ease;
      transition-delay: 1.5s;
      transform: scaleX(0); }
    .about-me__skills .communication:after {
      width: 55%;
      transform-origin: 0 50%;
      transition: transform 2s ease;
      transition-delay: 2s;
      transform: scaleX(0); }
    .about-me__skills .animated .creativity:after {
      transform: scaleX(1); }
    .about-me__skills .animated .problem-solving:after {
      transform: scaleX(1); }
    .about-me__skills .animated .detail-oriented:after {
      transform: scaleX(1); }
    .about-me__skills .animated .communication:after {
      transform: scaleX(1); }
  .about-me__biographi {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 9.1rem 0; }
    @media (max-width: 1199px) {
      .about-me__biographi {
        padding: 5.1rem 0; } }
    @media (max-width: 479px) {
      .about-me__biographi {
        padding: 5.1rem 0; } }
    .about-me__biographi .button--white {
      position: relative;
      z-index: 1; }
    .about-me__biographi h2 {
      margin-bottom: 1rem;
      z-index: 2;
      flex-shrink: 0; }
    .about-me__biographi h3 {
      margin-bottom: 3rem;
      color: #fffefe;
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: 3.2px;
      z-index: 2; }
    .about-me__biographi p {
      margin-bottom: 6rem;
      font-size: 1.3rem;
      color: #fffefe;
      font-weight: 300;
      line-height: 2em;
      z-index: 2;
      width: 100%; }
      @media (max-width: 1199px) {
        .about-me__biographi p {
          margin-bottom: 3rem; } }
    .about-me__biographi .button {
      align-self: flex-start; }
    .about-me__biographi .rectangle {
      width: 270px;
      height: 270px; }
      .about-me__biographi .rectangle-bg {
        position: absolute;
        top: 5%;
        right: -40%;
        opacity: 0.18;
        z-index: 0; }
    .about-me__biographi .circle-bg {
      position: absolute;
      bottom: 4rem;
      left: 21rem;
      width: 11rem;
      height: 11rem;
      opacity: 0.18;
      border-radius: 50%;
      border: 1px solid #e3e3e3; }

.rectangle {
  position: relative;
  border: 1px solid #e3e3e3; }
  .rectangle:before, .rectangle:after {
    content: '';
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    width: 8px;
    height: 8px;
    border: 1px solid #e3e3e3; }
  .rectangle:before {
    top: -4px; }
  .rectangle:after {
    bottom: -4px; }
  .rectangle--circle {
    height: 100%;
    border-radius: 50%;
    border: 1px solid #e3e3e3; }
    .rectangle--circle:before, .rectangle--circle:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      border: 1px solid #e3e3e3; }
    .rectangle--circle:before {
      left: -4px; }
    .rectangle--circle:after {
      right: -4px; }

.contact-form {
  padding-top: 5.8rem;
  min-height: 58rem;
  background-color: #fff;
  position: relative; }
  @media (max-width: 1199px) {
    .contact-form {
      min-height: 45rem; } }
  .contact-form:before {
    content: '';
    position: absolute;
    bottom: -6%;
    left: calc(50% - 75rem);
    width: 75rem;
    height: 2.2rem;
    background-color: #252525; }
    @media (max-width: 767px) {
      .contact-form:before {
        display: none; } }
  .contact-form .container {
    position: relative; }
  .contact-form .form--glob {
    position: absolute;
    top: 0;
    left: -15rem;
    width: 33%; }
    @media (max-width: 991px) {
      .contact-form .form--glob {
        left: -10rem; } }
  .contact-form .form--mount {
    position: absolute;
    bottom: -3rem;
    left: -6%;
    z-index: 3;
    max-width: 64rem; }
    @media (max-width: 1199px) {
      .contact-form .form--mount {
        width: 60%;
        bottom: -3px; } }
    @media (max-width: 991px) {
      .contact-form .form--mount {
        left: -19%; } }
    @media (max-width: 767px) {
      .contact-form .form--mount {
        display: none; } }
  .contact-form__inner {
    display: flex;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 10% 100%; }
    @media (max-width: 767px) {
      .contact-form__inner {
        flex-direction: column; } }
  .contact-form__left {
    position: relative;
    padding: 16rem 0;
    width: 30%;
    z-index: 4; }
    .contact-form__left:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      margin-left: -17rem;
      background-size: contain;
      background-repeat: no-repeat; }
    @media (max-width: 1199px) {
      .contact-form__left {
        padding: 12rem 0; } }
    @media (max-width: 991px) {
      .contact-form__left {
        width: 33%; } }
    @media (max-width: 767px) {
      .contact-form__left {
        width: 100%;
        order: 2; } }
    @media (max-width: 767px) {
      .contact-form__left {
        display: none; } }
    .contact-form__left--circl {
      position: absolute;
      top: 2rem;
      right: 30%;
      width: 12rem;
      height: 12rem;
      border-radius: 50%;
      border: 1px solid #e3e3e3; }
  .contact-form__descr {
    margin-right: 6.8rem;
    max-width: 25rem; }
    @media (max-width: 991px) {
      .contact-form__descr {
        margin-right: 3rem; } }
    .contact-form__descr p {
      color: #252525;
      font-size: 1.4rem;
      line-height: 1.9em;
      font-weight: 400; }
  .contact-form__right {
    position: relative;
    padding-top: 7.5rem;
    width: 70%; }
    @media (max-width: 1199px) {
      .contact-form__right {
        padding-top: 7.5rem; } }
    @media (max-width: 991px) {
      .contact-form__right {
        width: 67%; } }
    @media (max-width: 767px) {
      .contact-form__right {
        width: 100%; } }
    @media (max-width: 479px) {
      .contact-form__right {
        padding-top: 2rem; } }
    .contact-form__right:before {
      content: '';
      position: absolute;
      top: 38%;
      left: -15%;
      z-index: 3;
      width: 43%;
      height: 2%;
      transform: rotate(-27deg);
      background-color: #252525; }
      @media (max-width: 991px) {
        .contact-form__right:before {
          display: none; } }
    .contact-form__right:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 17%;
      z-index: 2;
      width: 23%;
      height: 1%;
      transform: rotate(29deg);
      background-color: #252525; }
      @media (max-width: 991px) {
        .contact-form__right:after {
          display: none; } }
  .contact-form__right-inner {
    z-index: 2;
    padding: 5rem 0 7.2rem 26rem;
    min-height: 41rem;
    height: 100%;
    position: relative; }
    @media (max-width: 1199px) {
      .contact-form__right-inner {
        padding: 5rem 0 7.2rem 22rem; } }
    @media (max-width: 991px) {
      .contact-form__right-inner {
        padding: 4rem; } }
    @media (max-width: 991px) {
      .contact-form__right-inner {
        padding: 4rem 2rem; } }
    @media (max-width: 479px) {
      .contact-form__right-inner {
        padding: 3rem 2rem; } }
    .contact-form__right-inner .bg-line {
      position: absolute;
      right: -13%;
      top: 15%;
      width: 15%;
      height: 1%;
      background-color: #252525;
      transform: rotate(27deg); }
      @media (max-width: 991px) {
        .contact-form__right-inner .bg-line {
          display: none; } }
      .contact-form__right-inner .bg-line2 {
        right: -20%;
        top: 57%;
        width: 14%;
        transform: rotate(-34deg); }
    .contact-form__right-inner:before {
      content: '';
      position: absolute;
      top: 41%;
      left: 9%;
      z-index: 1;
      width: 23%;
      height: 1%;
      transform: rotate(-32deg);
      background-color: #252525; }
      @media (max-width: 991px) {
        .contact-form__right-inner:before {
          display: none; } }
    .contact-form__right-inner:after {
      content: '';
      position: absolute;
      top: 0;
      right: -37rem;
      bottom: 0;
      left: 0;
      z-index: 0;
      background-color: #b51622;
      background-image: url("../images/useful/bg/form-right-bg.png");
      background-size: cover;
      background-position: bottom;
      z-index: -1; }
      @media (max-width: 767px) {
        .contact-form__right-inner:after {
          right: 0; } }
  .contact-form__title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -3rem;
    left: 5.7rem;
    bottom: 0;
    z-index: 1;
    transform: rotate(270deg); }
    @media (max-width: 991px) {
      .contact-form__title {
        transform: rotate(0);
        position: static; } }
    .contact-form__title-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
    .contact-form__title span {
      color: #fffefe;
      font-size: 4rem;
      line-height: 1.2em;
      letter-spacing: 1.11px;
      font-family: 'Comforta', sans-serif;
      font-weight: 700; }
      @media (max-width: 1199px) {
        .contact-form__title span {
          font-size: 3rem; } }
  .contact-form .error {
    color: #fff;
    font-size: 1.2rem;
    letter-spacing: 0.5px; }

.form {
  position: relative;
  z-index: 2; }
  .form-group {
    margin-bottom: 2.3rem; }
  .form .textarea {
    resize: none;
    height: 8rem; }
    .form .textarea::placeholder {
      padding-top: 3rem; }
  .form .button--white:hover {
    background-color: #252525; }

.form-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 3.2rem; }
  @media (max-width: 767px) {
    .form-footer {
      flex-direction: column;
      align-items: flex-start; } }

.files {
  display: flex;
  align-items: center; }
  @media (max-width: 1199px) {
    .files {
      flex-direction: column;
      align-items: flex-start; } }
  @media (max-width: 767px) {
    .files {
      margin-bottom: 2rem;
      flex-direction: row;
      align-items: center; } }
  @media (max-width: 479px) {
    .files {
      flex-direction: column;
      align-items: flex-start; } }
  .files label {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    color: #fffefe;
    font-size: 1.4rem;
    font-family: 'Roboto';
    margin-right: 1rem; }
  .files input {
    opacity: 0;
    height: 0;
    width: 0; }
  .files .fileList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 50%; }
    .files .fileList li {
      position: relative;
      margin: 1px 0;
      padding: 4px 13px 2px 17px;
      max-width: 20rem;
      overflow: hidden;
      background-image: url("../images/useful/bg/file.png");
      background-position: left;
      background-repeat: no-repeat;
      background-size: 10px;
      color: #fff; }
      .files .fileList li:before {
        content: 'x';
        position: absolute;
        top: -2px;
        right: 2px; }
      @media (max-width: 479px) {
        .files .fileList li {
          max-width: 20rem; } }
    .files .fileList a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.add-icon {
  position: relative;
  margin-right: 1rem;
  display: inline-block;
  width: 3.7rem;
  height: 3.7rem;
  border: 2px solid #fff;
  color: #fff;
  font-size: 1.1rem; }
  .add-icon .icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; }

.portfolio {
  padding-top: 10.5rem;
  background-color: #fff; }
  @media (max-width: 479px) {
    .portfolio {
      padding-top: 4.5rem; } }
  .portfolio-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .portfolio h2 {
    margin-bottom: 1.8rem; }
  .portfolio-filter {
    margin-bottom: 3.5rem; }
    .portfolio-filter .filter__button {
      position: relative;
      margin-bottom: 1rem;
      display: inline-block;
      text-decoration: none;
      font-size: 1.4rem;
      text-transform: uppercase;
      color: #252525;
      font-weight: 500;
      letter-spacing: 2.8px;
      transition: color 0.3s;
      cursor: pointer; }
      @media (max-width: 767px) {
        .portfolio-filter .filter__button {
          letter-spacing: 1.3px;
          font-size: 1rem; } }
    .portfolio-filter .filter__button::after {
      position: absolute;
      bottom: -1rem;
      left: -1px;
      content: '';
      display: block;
      width: 0;
      height: 4px;
      background: #252525;
      transition: width 0.3s, background 0.3s; }
      @media (max-width: 767px) {
        .portfolio-filter .filter__button::after {
          bottom: -0.4rem; } }
    @media (min-width: 768px) {
      .portfolio-filter .filter__button:hover {
        color: #b51622; }
      .portfolio-filter .filter__button:hover::after {
        width: 100%;
        background: #b51622; } }
    .portfolio-filter .filter__button:not(:last-child) {
      margin-right: 4rem; }
      @media (max-width: 1199px) {
        .portfolio-filter .filter__button:not(:last-child) {
          margin-right: 3rem; } }
      @media (max-width: 767px) {
        .portfolio-filter .filter__button:not(:last-child) {
          margin-right: 1.2rem; } }
    .portfolio-filter .is-checked {
      color: #b51622; }
      .portfolio-filter .is-checked::after {
        position: absolute;
        bottom: -1rem;
        left: -1px;
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background: #b51622; }
        @media (max-width: 767px) {
          .portfolio-filter .is-checked::after {
            bottom: -0.4rem; } }
  .portfolio-contant {
    width: 100%;
    max-height: 54rem;
    overflow: hidden; }
    @media (max-width: 1199px) {
      .portfolio-contant {
        max-height: 42.6rem; } }
    @media (max-width: 991px) {
      .portfolio-contant {
        max-height: 33.4rem; } }
    @media (max-width: 767px) {
      .portfolio-contant {
        max-height: 40rem; } }
    @media (max-width: 767px) {
      .portfolio-contant {
        max-height: 31rem;
        max-height: inherit; } }
    .portfolio-contant:after {
      content: '';
      display: block;
      clear: both; }
  .portfolio .gutter-sizer {
    width: 3rem; }
  .portfolio .gutter-sizer {
    width: 30px; }
  .portfolio-item {
    position: relative;
    margin-bottom: 3rem;
    width: calc(25% - 22.5px);
    height: 24rem; }
    @media (max-width: 1199px) {
      .portfolio-item {
        height: 18.3rem; } }
    @media (max-width: 991px) {
      .portfolio-item {
        height: 13.7rem; } }
    @media (max-width: 767px) {
      .portfolio-item {
        width: calc(25% - 22.5px); } }
    @media (max-width: 479px) {
      .portfolio-item {
        width: calc(50% - 15px); } }
    .portfolio-item:hover .portfolio-item__links {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease, visibility 0.3s ease; }
    .portfolio-item:hover .portfolio-item__links-demo {
      margin-bottom: 1.6rem; }
    .portfolio-item--vr {
      width: calc(25% - 22.5px);
      height: 51rem; }
      @media (max-width: 1199px) {
        .portfolio-item--vr {
          height: 39.6rem; } }
      @media (max-width: 991px) {
        .portfolio-item--vr {
          height: 30.4rem; } }
      @media (max-width: 767px) {
        .portfolio-item--vr {
          width: calc(25% - 22.5px); } }
      @media (max-width: 479px) {
        .portfolio-item--vr {
          width: calc(50% - 15px);
          height: 25.4rem; } }
    .portfolio-item--hr {
      width: calc(50% - 15px); }
      @media (max-width: 767px) {
        .portfolio-item--hr {
          width: calc(50% - 15px); } }
      @media (max-width: 479px) {
        .portfolio-item--hr {
          height: 10.7rem; } }
      .portfolio-item--hr .portfolio-item__links {
        flex-direction: row; }
      .portfolio-item--hr:hover .portfolio-item__links-demo {
        margin-bottom: 0;
        margin-right: 1.6rem; }
    .portfolio-item img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      object-fit: cover; }
    .portfolio-item__links {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: opacity 0.3s ease, visibility 0.3s ease; }
      .portfolio-item__links:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #313131;
        opacity: 0.5; }
      .portfolio-item__links .portfolio-item__links-demo, .portfolio-item__links .portfolio-item__links-source {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5rem;
        height: 5rem;
        background-color: #fff;
        color: #252525;
        transition: background-color 0.3s ease, color 0.3s ease, margin 0.3s ease; }
      .portfolio-item__links-demo:hover {
        background-color: #b51622;
        color: #fff; }
      .portfolio-item__links-source:hover {
        background-color: #252525;
        color: #fff; }
      .portfolio-item__links .icon-eye {
        font-size: 2rem; }
      .portfolio-item__links .icon-unlink {
        font-size: 1.6rem; }
  .portfolio-inside {
    padding: 8rem 0 15rem; }
    @media (max-width: 767px) {
      .portfolio-inside {
        padding: 3rem 0 5rem; } }
    .portfolio-inside__picture {
      width: 100%;
      position: relative;
      margin-bottom: 7.3rem; }
      @media (max-width: 767px) {
        .portfolio-inside__picture {
          margin-bottom: 3rem; } }
      .portfolio-inside__picture .h-object-fit {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%; }
      .portfolio-inside__picture:before {
        padding-bottom: 51.0711225364%;
        content: ' ';
        visibility: hidden;
        display: block; }
      .portfolio-inside__picture-btn {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 57px;
        height: 57px;
        background-color: #b51622;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color .5s ease; }
        .portfolio-inside__picture-btn.ext_link {
          right: 70px; }
        @media (max-width: 479px) {
          .portfolio-inside__picture-btn {
            width: 37px;
            height: 37px; } }
        .portfolio-inside__picture-btn .icon {
          font-size: 25px;
          color: white; }
          @media (max-width: 479px) {
            .portfolio-inside__picture-btn .icon {
              font-size: 18px; } }
        .portfolio-inside__picture-btn:hover {
          background-color: #252525; }
    .portfolio-inside__back {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #e2e2e2; }
      @media (max-width: 479px) {
        .portfolio-inside__back {
          justify-content: center;
          border: none; } }
    .portfolio-inside__info {
      margin-bottom: 95px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between; }
      @media (max-width: 767px) {
        .portfolio-inside__info {
          margin-bottom: 45px;
          flex-wrap: wrap; } }
      .portfolio-inside__info-left {
        flex-shrink: 0;
        width: 57%;
        padding-top: 14px; }
        @media (max-width: 767px) {
          .portfolio-inside__info-left {
            width: 100%;
            margin-bottom: 35px; } }
      .portfolio-inside__info-right {
        flex-shrink: 0;
        width: 36%; }
        @media (max-width: 767px) {
          .portfolio-inside__info-right {
            width: 100%; } }
      .portfolio-inside__info-ttl {
        font-size: 2.4rem;
        font-family: 'Comforta', sans-serif;
        color: #252525;
        letter-spacing: 0.22px;
        padding-bottom: 16px;
        margin-bottom: 31px;
        border-bottom: 1px solid rgba(180, 180, 180, 0.41); }
        @media (max-width: 767px) {
          .portfolio-inside__info-ttl {
            padding-bottom: 10px;
            margin-bottom: 24px; } }
        @media (max-width: 479px) {
          .portfolio-inside__info-ttl {
            font-size: 2rem; } }
      .portfolio-inside__info-about {
        margin-bottom: 23px; }
        .portfolio-inside__info-about span:first-child {
          color: #000000;
          font-family: "Oswald";
          font-size: 12px;
          font-weight: 500;
          line-height: 2em;
          text-transform: uppercase;
          letter-spacing: 2.4px;
          display: block;
          text-align: right;
          margin-bottom: 2px; }
          @media (max-width: 1199px) {
            .portfolio-inside__info-about span:first-child {
              margin-bottom: 0; } }
          @media (max-width: 991px) {
            .portfolio-inside__info-about span:first-child {
              font-size: 10px; } }
        .portfolio-inside__info-about span:last-child {
          line-height: 1.2em;
          font-family: 'Comforta', sans-serif;
          font-weight: 700;
          letter-spacing: .33px;
          font-size: 36px;
          color: #000000;
          display: block;
          text-align: right; }
          @media (max-width: 1199px) {
            .portfolio-inside__info-about span:last-child {
              font-size: 26px; } }
          @media (max-width: 991px) {
            .portfolio-inside__info-about span:last-child {
              font-size: 18px; } }
      .portfolio-inside__info-sub {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 86px; }
        @media (max-width: 1199px) {
          .portfolio-inside__info-sub {
            margin-bottom: 40px; } }
        .portfolio-inside__info-sub-line {
          display: flex;
          align-items: center; }
          .portfolio-inside__info-sub-line:not(:last-child) {
            margin-bottom: 25px; }
            @media (max-width: 1199px) {
              .portfolio-inside__info-sub-line:not(:last-child) {
                margin-bottom: 5px; } }
          .portfolio-inside__info-sub-line span {
            margin-right: 5px; }
          .portfolio-inside__info-sub-line span, .portfolio-inside__info-sub-line time {
            color: #000000;
            font-family: "Roboto";
            font-size: 13px;
            font-weight: 300;
            line-height: 1.6em;
            letter-spacing: .33px; }
        .portfolio-inside__info-sub-share {
          display: flex;
          align-items: center; }
          .portfolio-inside__info-sub-share a {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 8px; }
            .portfolio-inside__info-sub-share a:hover .icon {
              color: #b51622; }
            .portfolio-inside__info-sub-share a .icon {
              font-size: 18px;
              color: #000000;
              transition: color .3s ease; }
              .portfolio-inside__info-sub-share a .icon-be {
                font-size: 22px; }
      .portfolio-inside__info-works-txt {
        color: #000000;
        font-family: 'Comforta', sans-serif;
        font-size: 24px;
        margin-bottom: 30px;
        display: block;
        text-align: right; }
        @media (max-width: 767px) {
          .portfolio-inside__info-works-txt {
            text-align: center; } }
        @media (max-width: 479px) {
          .portfolio-inside__info-works-txt {
            margin-bottom: 20px;
            font-size: 20px; } }
      .portfolio-inside__info-works-inner {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end; }
        @media (max-width: 1199px) {
          .portfolio-inside__info-works-inner {
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start; } }
        @media (max-width: 767px) {
          .portfolio-inside__info-works-inner {
            flex-direction: row;
            align-items: center;
            justify-content: center; } }
        @media (max-width: 479px) {
          .portfolio-inside__info-works-inner {
            flex-direction: column; } }
      .portfolio-inside__info-works-item {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .portfolio-inside__info-works-item:not(:last-child) {
          margin-right: 35px; }
          @media (max-width: 1199px) {
            .portfolio-inside__info-works-item:not(:last-child) {
              margin-right: 0;
              margin-bottom: 25px; } }
          @media (max-width: 767px) {
            .portfolio-inside__info-works-item:not(:last-child) {
              margin-right: 15px;
              margin-bottom: 0; } }
          @media (max-width: 479px) {
            .portfolio-inside__info-works-item:not(:last-child) {
              margin-right: 0;
              margin-bottom: 15px; } }
        .portfolio-inside__info-works-item-img {
          width: 92px;
          height: 92px;
          margin-bottom: 13px;
          position: relative; }
          .portfolio-inside__info-works-item-img .h-object-fit {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%; }
          .portfolio-inside__info-works-item-img img {
            border-radius: 100px;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px; }
        .portfolio-inside__info-works-item span {
          max-width: 100px;
          color: #000000;
          font-family: "Roboto";
          font-size: 13px;
          font-weight: 300;
          line-height: 1.4em;
          text-align: center;
          letter-spacing: .33px; }
          .portfolio-inside__info-works-item span a {
            color: #000000; }
      .portfolio-inside__info-inner {
        display: flex;
        align-items: flex-start; }
      .portfolio-inside__info p {
        color: #000000;
        font-size: 13px;
        line-height: 1.7em;
        font-weight: 300;
        letter-spacing: .33px; }
        .portfolio-inside__info p:not(:last-child) {
          margin-bottom: 48px; }
          @media (max-width: 1199px) {
            .portfolio-inside__info p:not(:last-child) {
              margin-bottom: 40px; } }
          @media (max-width: 767px) {
            .portfolio-inside__info p:not(:last-child) {
              margin-bottom: 20px; } }

.back-btn {
  height: 46px;
  line-height: 46px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  transition: background-color .5s ease; }
  .back-btn:hover {
    background-color: #b51622; }
  .back-btn span {
    color: #ffffff;
    font-family: "Oswald";
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2.4px;
    padding-left: 12px; }
  .back-btn .icon {
    color: white;
    font-size: 12px; }

.breadcrumbs {
  position: relative;
  z-index: 1;
  margin-top: -1px; }
  .breadcrumbs .container-inner {
    border-bottom: 1px solid #e2e2e2; }
    .breadcrumbs .container-inner .container {
      display: flex;
      align-items: center;
      height: 8.5rem; }
      @media (max-width: 767px) {
        .breadcrumbs .container-inner .container {
          height: 4rem; } }
  .breadcrumbs__inner {
    position: relative;
    height: fit-content; }
    .breadcrumbs__inner:after {
      content: '';
      position: absolute;
      top: 50%;
      right: -9.8rem;
      width: 8.8rem;
      height: 3px;
      background-color: #dddddd; }
      @media (max-width: 767px) {
        .breadcrumbs__inner:after {
          right: -5.8rem;
          width: 4rem; } }
  .breadcrumbs__item {
    font-size: 2.2rem;
    font-weight: 300;
    font-family: 'Comforta', sans-serif;
    display: inline-block;
    color: #252525; }
    .breadcrumbs__item a {
      color: #252525; }
    @media (max-width: 479px) {
      .breadcrumbs__item {
        font-size: 1.8rem; } }
    .breadcrumbs__item.active {
      margin-left: 1rem;
      color: #a5a5a5;
      font-size: 1.4rem; }
      .breadcrumbs__item.active a {
        color: #a5a5a5; }
      @media (max-width: 479px) {
        .breadcrumbs__item.active {
          font-size: 1.1rem; } }

.breadcrumbs > .container {
  max-width: 1524px !important; }

.bread-crumbs--border {
  border-top: 1px solid #e2e2e2; }

.proud-of {
  padding: 6rem 0 11rem;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (max-width: 479px) {
    .proud-of {
      padding: 6rem 0 6rem; } }
  .proud-of .title--black {
    margin-bottom: 6rem; }
    @media (max-width: 479px) {
      .proud-of .title--black {
        margin-bottom: 3rem; } }

.skills__wrapp {
  margin-bottom: 3.8rem;
  display: flex;
  justify-content: space-between;
  max-width: 100%; }
  @media (max-width: 991px) {
    .skills__wrapp {
      flex-wrap: wrap;
      justify-content: space-around; } }
  @media (max-width: 767px) {
    .skills__wrapp {
      margin-bottom: 0;
      justify-content: space-between; } }

.skills__item {
  color: #252525;
  width: 18%;
  transition: color 0.3s; }
  @media (max-width: 991px) {
    .skills__item {
      width: 30%;
      margin-bottom: 4rem; } }
  @media (max-width: 767px) {
    .skills__item {
      width: 47%;
      margin-bottom: 4rem;
      letter-spacing: 2px;
      font-size: 1.6rem; } }
  .skills__item:hover {
    color: #b51622; }
    .skills__item:hover .skills__icon {
      border-color: #b51622; }

.skills__icon {
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.8rem;
  height: 6.8rem;
  border: 1px solid #252525;
  border-radius: 50%;
  transition: border-color 0.3s; }
  @media (max-width: 767px) {
    .skills__icon {
      margin-bottom: 2rem; } }
  .skills__icon .icon {
    font-size: 4rem; }

.skills__title {
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2.5px; }
  @media (max-width: 767px) {
    .skills__title {
      letter-spacing: 2.3px;
      font-size: 1.6rem; } }

.experience {
  position: relative;
  padding: 9rem 0 10rem;
  background-color: #b51622; }
  @media (max-width: 767px) {
    .experience {
      padding: 5rem 0 5rem; } }
  .experience:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("../images/useful/bg/about-page.png");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5; }
  .experience-inner {
    position: relative;
    z-index: 2; }
  .experience h2 {
    margin-bottom: 3rem;
    display: block;
    text-align: center; }
    @media (max-width: 767px) {
      .experience h2 {
        margin-bottom: 2rem; } }
  .experience h3 {
    margin-bottom: 2.9rem; }
  .experience__text {
    column-count: 2;
    column-gap: 3rem;
    text-align: left; }
    @media (max-width: 767px) {
      .experience__text {
        column-count: 1; } }
  .experience p {
    margin-bottom: 3.5rem;
    font-size: 13px;
    color: #fff;
    line-height: 1.5em;
    letter-spacing: 0.4px;
    text-align: center;
    text-align: left; }
    @media (max-width: 767px) {
      .experience p {
        margin-bottom: 2rem; } }

.cooperation {
  padding: 6rem 0 10rem; }
  @media (max-width: 767px) {
    .cooperation {
      padding: 4rem 0 4rem; } }
  .cooperation__info h3 {
    margin-bottom: 3rem; }
  .cooperation__slider-wrap {
    display: flex;
    align-items: stretch;
    min-height: 47rem; }
  .cooperation__slider-left {
    position: relative;
    z-index: 1;
    padding: 3.5rem 3rem;
    width: 30%; }
    @media (max-width: 991px) {
      .cooperation__slider-left {
        padding: 2rem;
        width: 35%; } }
    @media (max-width: 767px) {
      .cooperation__slider-left {
        display: none; } }
    .cooperation__slider-left:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 65%;
      left: 0;
      background-image: linear-gradient(to bottom, #171717 0%, rgba(23, 23, 23, 0) 100%); }
    .cooperation__slider-left img {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .cooperation__slider-left p {
      position: relative;
      z-index: 2; }
  .cooperation__slider-right {
    position: relative;
    padding: 1rem 8rem;
    width: 70%; }
    @media (max-width: 991px) {
      .cooperation__slider-right {
        padding: 1rem 7rem 1rem 5rem;
        width: 65%; } }
    @media (max-width: 767px) {
      .cooperation__slider-right {
        width: 100%; } }
    @media (max-width: 479px) {
      .cooperation__slider-right {
        padding: 1rem 6.2rem 1rem 0; } }
    .cooperation__slider-right .slick-arrow-wrap {
      bottom: 0;
      right: 0; }
      @media (max-width: 767px) {
        .cooperation__slider-right .slick-arrow-wrap {
          bottom: 40%; } }
  .cooperation__slider {
    overflow: hidden; }
    .cooperation__slider-item {
      display: inline-block; }
      .cooperation__slider-item .cooperation__slider-text {
        position: relative;
        font-family: 'Roboto'; }
      .cooperation__slider-item .cooperation__slider-text:after {
        content: '';
        position: absolute;
        left: 0rem;
        top: -4.5rem;
        width: 3.3rem;
        height: 2.9rem;
        background-image: url("../images/tmp_file/who-i-am/quote.png"); }
    .cooperation__slider-img {
      margin: auto;
      margin-bottom: 3rem;
      border-radius: 50%;
      width: 12rem;
      height: 12rem; }
    .cooperation__slider-name {
      margin-bottom: 2rem;
      color: #252525;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 3.6px;
      text-align: center; }
    .cooperation__slider-position {
      margin-bottom: 2rem;
      font-size: 18px;
      font-weight: 400;
      color: #b51622;
      letter-spacing: 1.8px;
      text-align: center; }

.blog {
  padding-bottom: 10rem; }
  .blog-inner {
    padding-top: 8rem;
    border-bottom: 1px solid #e2e2e2; }
    @media (max-width: 479px) {
      .blog-inner {
        padding-top: 4rem; } }
  .blog-article {
    margin-bottom: 11rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    @media (max-width: 991px) {
      .blog-article {
        flex-direction: column; } }
    @media (max-width: 767px) {
      .blog-article {
        margin-bottom: 4rem; } }
    .blog-article__img {
      width: 48.5%; }
      .blog-article__img:hover img {
        transform: scale(1.05); }
      @media (max-width: 991px) {
        .blog-article__img {
          margin-bottom: 3rem;
          width: 100%; } }
      @media (max-width: 479px) {
        .blog-article__img {
          margin-bottom: 2rem; } }
      .blog-article__img img {
        transition: transform 0.3s;
        width: 100%; }
    .blog-article__info {
      width: 48.5%; }
      @media (max-width: 991px) {
        .blog-article__info {
          width: 100%; } }
      .blog-article__info-title {
        margin-bottom: 1.6rem;
        font-family: Comforta, sans-serif;
        font-size: 2.4rem;
        font-weight: 700;
        color: #252525;
        letter-spacing: 0.22px;
        transition: color 0.3s; }
        .blog-article__info-title:hover {
          color: #b51622; }
        @media (max-width: 479px) {
          .blog-article__info-title {
            margin-bottom: 3px;
            font-size: 2rem; } }
      .blog-article__info .p--bl {
        margin-bottom: 4.8rem; }
        @media (max-width: 1199px) {
          .blog-article__info .p--bl {
            margin-bottom: 3rem; } }
        @media (max-width: 479px) {
          .blog-article__info .p--bl {
            margin-bottom: 2rem; } }
      .blog-article__info .button {
        width: 13rem; }
  .blog-navigation, .blog .wp-pagenavi {
    display: flex; }
    .blog-navigation .pages, .blog .wp-pagenavi .pages {
      display: none; }
    .blog-navigation__item, .blog-navigation > span, .blog-navigation > a, .blog .wp-pagenavi__item, .blog .wp-pagenavi > span, .blog .wp-pagenavi > a {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      color: #b51622;
      font-size: 1.2rem;
      font-weight: 700;
      transition: color 0.3s, background-color 0.3s; }
      .blog-navigation__item.active, .blog-navigation__item.current, .blog-navigation > span.active, .blog-navigation > span.current, .blog-navigation > a.active, .blog-navigation > a.current, .blog .wp-pagenavi__item.active, .blog .wp-pagenavi__item.current, .blog .wp-pagenavi > span.active, .blog .wp-pagenavi > span.current, .blog .wp-pagenavi > a.active, .blog .wp-pagenavi > a.current {
        margin: 0;
        color: #fff;
        background-color: #b51622; }
      .blog-navigation__item:hover, .blog-navigation > span:hover, .blog-navigation > a:hover, .blog .wp-pagenavi__item:hover, .blog .wp-pagenavi > span:hover, .blog .wp-pagenavi > a:hover {
        color: #fff;
        background-color: #b51622; }
      .blog-navigation__item--arr, .blog-navigation > span--arr, .blog-navigation > a--arr, .blog .wp-pagenavi__item--arr, .blog .wp-pagenavi > span--arr, .blog .wp-pagenavi > a--arr {
        font-size: 1.6rem; }

.blog-banner {
  padding: 10rem 0 3rem;
  background-image: url("../images/tmp_file/blog-page/bg-layout.png");
  background-size: cover; }
  @media (max-width: 767px) {
    .blog-banner {
      padding: 3rem 0 3rem; } }
  .blog-banner__content {
    max-width: 86rem; }
  .blog-banner__title-wrap {
    position: relative;
    z-index: 1;
    padding: 4.5rem; }
    @media (max-width: 767px) {
      .blog-banner__title-wrap {
        padding: 2rem; } }
    .blog-banner__title-wrap:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #FFF;
      opacity: 0.9; }
  .blog-banner__autor-wrap {
    padding: 11px 4.5rem;
    background-color: #b51622; }

.banner__title {
  color: #252525;
  font-family: 'Comforta', sans-serif;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.99px; }
  @media (max-width: 767px) {
    .banner__title {
      font-size: 20px; } }

.banner__title--cap {
  text-transform: none; }

.banner__autor {
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.1em;
  font-weight: 500;
  letter-spacing: 2.04px; }

.typography {
  padding-bottom: 150px; }
  @media (max-width: 767px) {
    .typography {
      padding-bottom: 50px; } }
  .typography-inner {
    padding: 80px 0 40px;
    border-bottom: 1px solid #e2e2e2; }
    @media (max-width: 767px) {
      .typography-inner {
        padding: 40px 0 20px; } }
    .typography-inner:last-child {
      border-bottom: none; }
  .typography p {
    margin-bottom: 40px;
    font-size: 13px;
    line-height: 22px;
    color: #000000;
    letter-spacing: 0.33px; }
    @media (max-width: 767px) {
      .typography p {
        margin-bottom: 20px; } }
  .typography .block-2col {
    column-count: 2;
    column-gap: 3rem;
    margin-bottom: 3rem; }
    @media (max-width: 767px) {
      .typography .block-2col {
        column-count: 1;
        margin-bottom: 0; } }
  .typography .block-list h2 {
    margin-bottom: 2.5rem; }
    @media (max-width: 767px) {
      .typography .block-list h2 {
        margin-bottom: 1rem; } }
  .typography .block-list p {
    margin-bottom: 2.5rem; }
  .typography .block-list ul {
    margin-bottom: 2.5rem; }
  .typography blockquote {
    position: relative;
    padding: 25px 30px 25px 70px;
    margin-bottom: 40px;
    background-color: #f7f7f7;
    font-style: italic;
    font-weight: 500;
    line-height: 22px; }
    @media (max-width: 479px) {
      .typography blockquote {
        padding: 15px 15px 15px 50px; } }
    .typography blockquote:before {
      content: '';
      position: absolute;
      top: 30px;
      left: 25px;
      display: block;
      width: 3.3rem;
      height: 2.9rem;
      background-image: url(../images/tmp_file/blog-page/blog-quote.png);
      background-size: cover; }
      @media (max-width: 479px) {
        .typography blockquote:before {
          top: 15px;
          left: 15px;
          width: 2.5rem;
          height: 2.2rem; } }
    .typography blockquote p {
      margin-bottom: 0;
      font-style: italic;
      font-weight: 500;
      line-height: 22px; }
  .typography .name-quote {
    text-align: right;
    font-style: italic;
    font-weight: 500;
    line-height: 22px; }
  .typography h2 {
    color: #000000;
    font-family: 'Comforta', sans-serif;
    font-size: 24px;
    letter-spacing: 0.22px; }
  .typography li {
    position: relative;
    padding-left: 20px;
    font-size: 13px;
    line-height: 22px;
    color: #000000;
    letter-spacing: 0.33px; }
    .typography li:before {
      content: '';
      position: absolute;
      top: calc(50% - 4px);
      left: 1px;
      bottom: 0;
      width: 8px;
      height: 8px;
      background-color: #b51622; }

.page-portfolio-filter {
  padding: 8rem 0 15rem; }
  @media (max-width: 767px) {
    .page-portfolio-filter {
      padding: 3rem 0 5rem; } }
  .page-portfolio-filter .container {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #e2e2e2; }
  .page-portfolio-filter__inner {
    padding: 0 15px; }
  .page-portfolio-filter .portfolio-item__wrap {
    margin-bottom: 90px;
    margin-right: 19.6px;
    margin-left: 19.6px;
    max-width: 350px; }
    @media (max-width: 1199px) {
      .page-portfolio-filter .portfolio-item__wrap {
        margin-bottom: 50px;
        margin-right: 18px;
        margin-left: 18px; } }
    @media (max-width: 991px) {
      .page-portfolio-filter .portfolio-item__wrap {
        margin-bottom: 40px;
        margin-right: 15.5px;
        margin-left: 15.5px; } }
    @media (max-width: 767px) {
      .page-portfolio-filter .portfolio-item__wrap {
        margin-bottom: 30px;
        margin-right: 2%;
        margin-left: 2%;
        width: 29%; } }
  .page-portfolio-filter .portfolio-contant {
    max-height: none; }
  .page-portfolio-filter .portfolio-item {
    width: 35rem;
    height: 35rem; }
    @media (max-width: 1199px) {
      .page-portfolio-filter .portfolio-item {
        width: 27rem;
        height: 26rem; } }
    @media (max-width: 991px) {
      .page-portfolio-filter .portfolio-item {
        width: 20.92rem;
        height: 20rem; } }
    @media (max-width: 767px) {
      .page-portfolio-filter .portfolio-item {
        width: 100%;
        height: 18rem;
        margin-bottom: 1.5rem; } }
    @media (max-width: 600px) {
      .page-portfolio-filter .portfolio-item {
        width: 100%;
        height: 16rem; } }
    @media (max-width: 479px) {
      .page-portfolio-filter .portfolio-item {
        width: 100%;
        height: 12rem;
        margin-bottom: 1rem; } }
    .page-portfolio-filter .portfolio-item--vr {
      height: 55rem; }
      @media (max-width: 1199px) {
        .page-portfolio-filter .portfolio-item--vr {
          height: 42rem; } }
      @media (max-width: 991px) {
        .page-portfolio-filter .portfolio-item--vr {
          height: 27.1rem; } }
      @media (max-width: 767px) {
        .page-portfolio-filter .portfolio-item--vr {
          height: 25rem; } }
      @media (max-width: 600px) {
        .page-portfolio-filter .portfolio-item--vr {
          height: 24rem; } }
      @media (max-width: 479px) {
        .page-portfolio-filter .portfolio-item--vr {
          height: 17rem; } }
  .page-portfolio-filter .portfolio-item__descr h3 {
    margin-bottom: 1rem;
    color: #252525;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2.4px; }
    @media (max-width: 479px) {
      .page-portfolio-filter .portfolio-item__descr h3 {
        margin-bottom: 5px; } }
  .page-portfolio-filter .portfolio-item__descr h4 {
    color: #252525;
    font-family: 'Comforta', sans-serif;
    font-size: 2.4rem;
    letter-spacing: 0.22px;
    font-weight: 300; }
    .page-portfolio-filter .portfolio-item__descr h4 a {
      color: #252525; }
    @media (max-width: 991px) {
      .page-portfolio-filter .portfolio-item__descr h4 {
        font-size: 2.2rem; } }
    @media (max-width: 767px) {
      .page-portfolio-filter .portfolio-item__descr h4 {
        font-size: 2rem; } }
    @media (max-width: 479px) {
      .page-portfolio-filter .portfolio-item__descr h4 {
        font-size: 1.8rem; } }
  .page-portfolio-filter .button {
    margin-right: auto; }
    .page-portfolio-filter .button .icon-arrow-prev {
      transform: rotate(180deg);
      margin-left: 5px; }

.services-banner {
  padding: 16rem 0 6rem;
  position: relative; }
  .services-banner:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 31rem;
    background-image: url("../images/tmp_file/serv-page/bg-layout.png");
    background-size: cover; }
    @media (max-width: 767px) {
      .services-banner:before {
        height: 25rem; } }
    @media (max-width: 767px) {
      .services-banner:before {
        height: 17rem; } }
    @media (max-width: 479px) {
      .services-banner:before {
        height: 11rem; } }
  @media (max-width: 767px) {
    .services-banner {
      padding: 3rem 0 3rem; } }
  .services-banner__content {
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-bottom: 9rem; }
    @media (max-width: 767px) {
      .services-banner__content {
        margin-bottom: 3rem; } }
  .services-banner__item {
    position: relative;
    padding: 6.2rem 5.5rem;
    width: 35.5rem;
    height: 48rem;
    display: flex;
    align-items: flex-end;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    transition: background-color 0.3s ease, border 0.3s ease; }
    @media (max-width: 1199px) {
      .services-banner__item {
        width: 28rem;
        height: 39.4rem; } }
    @media (max-width: 991px) {
      .services-banner__item {
        width: 22rem;
        height: 30.5rem;
        padding: 2.5rem 1.5rem; } }
    @media (max-width: 767px) {
      .services-banner__item {
        width: 31%;
        height: 25.5rem;
        padding: 2.5rem 1.5rem; } }
    @media (max-width: 600px) {
      .services-banner__item {
        height: 20.5rem;
        padding: 2.5rem 1rem; } }
    @media (max-width: 550px) {
      .services-banner__item {
        height: 18.5rem; } }
    @media (max-width: 479px) {
      .services-banner__item {
        width: 32%;
        height: 13rem;
        padding: 2rem 5px; } }
    .services-banner__item:before {
      content: '';
      position: absolute;
      right: 15%;
      bottom: -5%;
      height: 95%;
      width: 4px;
      display: block;
      background-color: #b51622;
      transform: scaleY(0);
      transition: transform 0.3s ease; }
    .services-banner__item.active {
      background-color: #feccc5;
      border: 1px solid #feccc5; }
      .services-banner__item.active h3 {
        color: #b51622; }
      .services-banner__item.active:before {
        transform: scaleY(1); }
    .services-banner__item:hover {
      background-color: #feccc5;
      border: 1px solid #feccc5; }
      .services-banner__item:hover h3 {
        color: #b51622; }
      .services-banner__item:hover:before {
        transform: scaleY(1); }
    .services-banner__item h3 {
      color: #252525;
      font-size: 2.2rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 4.8px; }
      @media (max-width: 767px) {
        .services-banner__item h3 {
          font-size: 1.6rem;
          letter-spacing: 3px; } }
      @media (max-width: 550px) {
        .services-banner__item h3 {
          font-size: 1.5rem;
          letter-spacing: 2.7px; } }
      @media (max-width: 479px) {
        .services-banner__item h3 {
          font-size: 1.1rem;
          letter-spacing: 1px; } }

.tab-pane:not(.active) {
  display: none; }

.services-what-i-do {
  padding-bottom: 1rem; }
  .services-what-i-do .container {
    padding: 8rem 1.5rem 9rem;
    border-top: 1px solid #b4b4b4; }
    @media (max-width: 767px) {
      .services-what-i-do .container {
        padding: 5rem 1.5rem; } }
  @media (max-width: 767px) {
    .services-what-i-do {
      padding-bottom: 0; } }

.services-slider {
  overflow: hidden; }
  .services-slider__wrap {
    position: relative; }
    @media (max-width: 767px) {
      .services-slider__wrap {
        margin-top: 0; } }
    .services-slider__wrap .title--black {
      margin-bottom: 2rem; }
    .services-slider__wrap .slick-arrow-wrap {
      top: 0;
      right: 0;
      left: auto;
      bottom: auto;
      display: flex;
      flex-direction: row; }
      @media (max-width: 767px) {
        .services-slider__wrap .slick-arrow-wrap {
          top: 35rem; } }
    .services-slider__wrap .services-next {
      margin-top: 0;
      margin-left: 1px; }
    .services-slider__wrap .button {
      position: absolute;
      bottom: 0;
      left: 0;
      top: auto;
      right: auto; }
  .services-slider__item {
    display: inline-block; }
  .services-slider__item-inner {
    display: flex; }
    @media (max-width: 767px) {
      .services-slider__item-inner {
        flex-direction: column;
        align-items: center; } }
  .services-slider__text {
    margin-right: 5rem;
    padding-bottom: 7rem;
    width: 70%; }
    @media (max-width: 991px) {
      .services-slider__text {
        width: 60%; } }
    @media (max-width: 767px) {
      .services-slider__text {
        width: 100%;
        margin-right: 0;
        padding-bottom: 3rem;
        order: 2; } }
    .services-slider__text h3 {
      margin-bottom: 3rem;
      color: #b51622;
      font-size: 1.5rem;
      font-family: 'Oswald', sans-serif;
      letter-spacing: 3px;
      text-transform: uppercase;
      font-weight: 500; }
    .services-slider__text .p--bl {
      margin-bottom: 2rem; }
  .services-slider__img {
    position: relative;
    width: 30%;
    min-height: 28rem; }
    @media (max-width: 991px) {
      .services-slider__img {
        width: 40%; } }
    @media (max-width: 767px) {
      .services-slider__img {
        width: 30rem;
        margin-bottom: 7rem; } }
    .services-slider__img img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      margin: auto; }

.services-questions {
  position: relative;
  padding: 8.5rem 0;
  background-image: url("../images/tmp_file/serv-page/serv-bg2.png");
  /* background-size: cover; */ }
  .services-questions__info h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 4.5rem; }
  .services-questions .accordion {
    column-count: 2;
    column-fill: auto;
    column-gap: 3rem;
    min-height: 27.2rem; }
    @media (max-width: 991px) {
      .services-questions .accordion {
        column-count: 1; } }
  .services-questions .card {
    margin-bottom: 1rem;
    display: inline-block;
    width: 100%; }
  .services-questions .card-header {
    background-color: #b51622; }
    .services-questions .card-header button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 25px 10px 25px;
      width: 100%;
      min-height: 5.8rem;
      color: #fff;
      font-size: 14px;
      font-family: 'Roboto';
      letter-spacing: 0.35px;
      line-height: 1.7em;
      white-space: normal;
      text-align: left; }
      .services-questions .card-header button .icon-plus {
        flex-shrink: 0;
        margin-left: 1.5rem;
        display: none;
        font-size: 10px; }
        @media (max-width: 991px) {
          .services-questions .card-header button .icon-plus {
            margin-left: 2rem; } }
      .services-questions .card-header button .icon-minus {
        flex-shrink: 0;
        margin-left: 1.5rem;
        width: 10px;
        height: 2px;
        background-color: #fff; }
        @media (max-width: 991px) {
          .services-questions .card-header button .icon-minus {
            margin-left: 2rem; } }
      .services-questions .card-header button.collapsed .icon-plus {
        display: block; }
      .services-questions .card-header button.collapsed .icon-minus {
        display: none; }
  .services-questions .show {
    border-top: 1px solid rgba(254, 204, 197, 0.4); }
  .services-questions .card-body {
    padding: 20px 25px;
    background-color: #b51622; }
    .services-questions .card-body .p--wh {
      margin: 0; }

@media (max-width: 991px) {
  .portfolio-modal.modal {
    padding-left: 0; } }

.portfolio-modal.modal .modal-dialog {
  margin: auto;
  transform: translate(0, 10rem);
  max-width: 80%; }
  @media (max-width: 991px) {
    .portfolio-modal.modal .modal-dialog {
      max-width: 90%;
      transform: translate(0, 5rem); } }
  @media (max-width: 767px) {
    .portfolio-modal.modal .modal-dialog {
      transform: translate(0, 3rem); } }
  .portfolio-modal.modal .modal-dialog .close {
    position: absolute;
    top: 0;
    right: 100%;
    left: 0;
    font-size: 4rem; }
    .portfolio-modal.modal .modal-dialog .close span {
      display: block;
      line-height: 0.4em;
      color: white; }

.portfolio-modal.modal .modal-content {
  border: none;
  background-color: transparent; }

.portfolio-modal.modal .modal-header {
  border: none;
  align-items: center;
  justify-content: flex-end; }

.portfolio-modal.modal .modal-title {
  margin-right: 1.2rem;
  font-size: 1.6rem;
  color: #fff;
  letter-spacing: 0.15px;
  font-weight: 300;
  font-family: 'Comforta', sans-serif; }

.portfolio-modal.modal .modal-controls {
  padding: 1.5rem 0;
  color: #fff;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.37px;
  min-width: 5rem;
  min-height: 5rem;
  border: 1px solid #fff;
  border-radius: 0;
  text-transform: capitalize;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Comforta', sans-serif;
  text-shadow: none;
  background-color: transparent; }
  .portfolio-modal.modal .modal-controls:hover {
    font-weight: 400;
    background-color: #FFF;
    color: #252525 !important; }

.portfolio-modal.modal .modal-prev {
  margin-left: 8px; }

.page-contact {
  padding: 4rem 0 20rem 0; }
  .page-contact__inner {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 767px) {
      .page-contact__inner {
        flex-direction: column; } }
  @media (max-width: 767px) {
    .page-contact {
      padding: 4rem 0 6rem 0; } }

.contact__info {
  padding: 5rem;
  width: 49%;
  min-height: 47.5rem;
  border: 1px solid #dddddd; }
  @media (max-width: 1199px) {
    .contact__info {
      padding: 4rem; } }
  @media (max-width: 991px) {
    .contact__info {
      padding: 3rem; } }
  @media (max-width: 767px) {
    .contact__info {
      padding: 2rem;
      width: 100%;
      min-height: inherit;
      margin-bottom: 3rem; } }
  .contact__info .title--black {
    margin-bottom: 1.5rem;
    font-size: 3.2rem;
    line-height: 1.4em; }
    @media (max-width: 1199px) {
      .contact__info .title--black {
        font-size: 2.8rem; } }
    @media (max-width: 1199px) {
      .contact__info .title--black {
        font-size: 2.6rem; } }
  .contact__info .subtitle--red {
    margin-bottom: 3rem;
    font-size: 1.6rem; }
    @media (max-width: 1199px) {
      .contact__info .subtitle--red {
        font-size: 1.5rem; } }
  .contact__info a {
    color: #252525;
    font-size: 13px;
    font-weight: 300;
    font-family: 'Roboto';
    letter-spacing: 0.33px;
    transition: color 0.3s; }
    .contact__info a:hover {
      color: #b51622; }

.contact__info-mail, .contact__info-skype, .contact__social {
  position: relative;
  margin-bottom: 2rem;
  padding-left: 6rem;
  display: flex;
  min-height: 4rem; }

.contact__info-mail {
  flex-direction: column;
  align-items: flex-start; }

.contact__info-skype {
  align-items: center; }

.contact__social {
  align-items: center; }
  .contact__social-item {
    margin-right: 1.8rem; }
    .contact__social-item .icon {
      font-size: 1.7rem;
      color: #b5b5b5;
      transition: color 0.3s; }
      .contact__social-item .icon:hover {
        color: #252525; }

.contact__item-icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  color: #fff;
  background-color: #b51622;
  font-size: 1.8rem;
  font-weight: 500; }

.contact__form {
  padding: 5rem;
  width: 49%;
  min-height: 47.5rem;
  border: 1px solid #dddddd; }
  @media (max-width: 1199px) {
    .contact__form {
      padding: 3rem; } }
  @media (max-width: 991px) {
    .contact__form {
      padding: 3rem; } }
  @media (max-width: 767px) {
    .contact__form {
      padding: 2rem;
      width: 100%;
      min-height: inherit; } }
  .contact__form .form-control {
    border-bottom: 4px solid #e2e2e2;
    color: #252525; }
    .contact__form .form-control::placeholder {
      color: #252525; }
  .contact__form .files {
    flex-shrink: 0; }
  .contact__form .add-icon {
    background-color: #b51622;
    color: #fff; }
  .contact__form label {
    border: #b51622;
    color: #252525; }
  .contact__form .fileList li {
    color: #252525;
    background-image: url("../images/useful/bg/file--wh.png"); }

.c-preloader {
  position: fixed;
  z-index: 1100;
  background-color: #FFF;
  width: 100vw;
  height: 100vh; }

.c-preloader.hidden {
  display: none; }

.c-preloader.hidden.loading {
  opacity: 0.4;
  display: block; }

.loader {
  position: absolute;
  top: calc(50% - 10vmin);
  left: calc(50% - 10vmin);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vmin;
  width: 10vmin;
  border: 6px solid #fff;
  border-top-color: #b51622;
  border-right-color: #b51622;
  border-radius: 100%;
  animation: spin 3800ms infinite linear; }
  @media (max-width: 767px) {
    .loader {
      height: 20vmin;
      width: 20vmin;
      border-top-color: #b51622;
      border-right-color: #b51622;
      border-radius: 100%; } }
  .loader-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(10vmin - 20px);
    height: calc(10vmin - 20px);
    border: 6px solid #fff;
    border-right-color: #2a2a2a;
    border-bottom-color: #2a2a2a;
    border-radius: 100%;
    animation: spin 4800ms infinite linear; }
    @media (max-width: 767px) {
      .loader-circle {
        width: calc(20vmin - 20px);
        height: calc(20vmin - 20px);
        border-right-color: #2a2a2a;
        border-bottom-color: #2a2a2a;
        border-radius: 100%; } }
    .loader-circle2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(10vmin - 40px);
      height: calc(10vmin - 40px);
      border: 6px solid #fff;
      border-right-color: #b51622;
      border-top-color: #b51622;
      border-radius: 100%;
      animation: spin 5800ms infinite linear; }
      @media (max-width: 767px) {
        .loader-circle2 {
          width: calc(20vmin - 40px);
          height: calc(20vmin - 40px);
          border-right-color: #b51622;
          border-top-color: #b51622;
          border-radius: 100%; } }
    .loader-circle3 {
      width: calc(10vmin - 60px);
      height: calc(10vmin - 60px);
      border: 6px solid #fff;
      border-bottom-color: #2a2a2a;
      border-left-color: #2a2a2a;
      border-radius: 100%;
      animation: spin 6800ms infinite linear; }
      @media (max-width: 767px) {
        .loader-circle3 {
          width: calc(20vmin - 60px);
          height: calc(20vmin - 60px);
          border-bottom-color: #2a2a2a;
          border-left-color: #2a2a2a;
          border-radius: 100%; } }

@keyframes "spin" {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.modal-get-in-touch .modal-header {
  padding-top: 5rem;
  border-bottom: none; }

.modal-get-in-touch .contact__form {
  padding-top: 2.5rem;
  width: 100%;
  min-height: inherit;
  border: none; }

.modal-get-in-touch .subtitle--black {
  margin: auto; }

.modal-get-in-touch .files {
  flex-direction: column;
  align-items: flex-start; }

.modal-get-in-touch .fileList {
  max-width: inherit; }

.modal-success .modal-dialog {
  max-width: 40rem; }

.modal-success .modal-header {
  justify-content: center;
  padding: 3rem 2rem; }

.modal-success .modal-body {
  padding: 3rem 2rem;
  text-align: center; }
  .modal-success .modal-body p {
    letter-spacing: 2.8px; }

.modal-success .tick {
  stroke-dasharray: 29px;
  stroke-dashoffset: 29px;
  animation: draw 0.6s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
  animation-delay: 1.2s; }

.modal-success .circle {
  fill-opacity: 0;
  stroke: #219a00;
  stroke-width: 16px;
  transform-origin: center;
  transform: scale(0);
  animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards; }

@keyframes grow {
  60% {
    transform: scale(0.8);
    stroke-width: 4px;
    fill-opacity: 0; }
  100% {
    transform: scale(0.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: #219a00; } }

@keyframes draw {
  100% {
    stroke-dashoffset: 0; } }

.modal-header {
  position: relative; }
  .modal-header .close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 4rem; }
    .modal-header .close span {
      display: block;
      line-height: 0.4em; }
