.breadcrumbs {
	position: relative;
	z-index: 1;
	margin-top: -1px;
	.container-inner {
		border-bottom: 1px solid #e2e2e2;
		.container {
			display: flex;
			align-items: center;
			height: 8.5rem;
			@media(max-width: $sw-xs-max) {
				height: 4rem;
			}
		}
	}
	
	&__inner {
		position: relative;
		height: fit-content;
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: -9.8rem;
			width: 8.8rem;
			height: 3px;
			background-color: #dddddd;
			@media(max-width: $sw-xs-max) {
				right: -5.8rem;
				width: 4rem;
			}
		}
	}

	&__item {
		font-size: 2.2rem;
		font-weight: 300;
		font-family: 'Comforta', sans-serif;
		display: inline-block;
		color: $black;
		a{
			color: $black;
		}
		@media(max-width: $sw-xx-max) {
			font-size: 1.8rem;
		}
		&.active {
			margin-left: 1rem;
			color: #a5a5a5;
			font-size: 1.4rem;
			a{
				color: #a5a5a5;
			}
			@media(max-width: $sw-xx-max) {
				font-size: 1.1rem;
			}
		}
	}
}

.breadcrumbs > .container {
	max-width: 1524px !important;
}

.bread-crumbs--border {
	border-top: 1px solid #e2e2e2;
}