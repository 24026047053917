.portfolio-modal.modal {
	@media(max-width: $sw-sm-max) {
		padding-left: 0;
	}
	.modal-dialog {
		margin: auto;
		transform: translate(0, 10rem);
		max-width: 80%;
		@media(max-width: $sw-sm-max) {
			max-width: 90%;
			transform: translate(0, 5rem);
		}
		@media(max-width: $sw-xs-max) {
			transform: translate(0, 3rem);
		}
		.close {
			position: absolute;
			top: 0;
			right: 100%;
			left: 0;
			font-size: 4rem;
			span {
				display: block;
				line-height: 0.4em;
				color: white;
			}
		}
	}

	.modal-content {
		border: none;
		background-color: transparent;	
	}

	.modal-header {
		border: none;
		align-items: center;
		justify-content: flex-end;
	}

	.modal-title {
		margin-right: 1.2rem;
		font-size: 1.6rem;
		color: $white;
		letter-spacing: 0.15px;
		font-weight: 300;
		font-family: 'Comforta', sans-serif;
	}
	.modal-controls {
		padding: 1.5rem 0;
		color: $white;
		font-weight: 300;
		font-size: 12px;
		letter-spacing: 0.37px;
		min-width: 5rem;
		min-height: 5rem;
		border: 1px solid $white;
		border-radius: 0;
		text-transform: capitalize;
		opacity: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: 'Comforta', sans-serif;
		text-shadow: none;
		background-color: transparent;
		&:hover {
			font-weight: 400;
			background-color: #FFF;
			color: $black!important;
		}
	}
	.modal-prev {
		margin-left: 8px;
	}
}