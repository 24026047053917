.main-banner {
	position: relative;
	top: -10rem;
	z-index: 0;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: -20%;
		left: 0;
		width: 100%;
		height: 130%;
		display: block;
		background-image: url('../images/useful/bg/top-bg.png');
		background-size: cover;
		
	}
	@media(max-width: $sw-xs-max) {
		padding-top: 5rem;
		/* height: 100vh; */
		height: auto;
		margin-bottom: -18rem;
	}

	&__slider-item {
		display: inline-block;
		&:not(.slick-current) .container {
			opacity: 0;
			transition: opacity 1s;
		}
	}

	.container {
		position: relative;
		z-index: 1;
		padding-top: 33rem;
		padding-bottom: 22rem;
		@media(max-width: $sw-md-max) {
			padding-top: 16rem;
			padding-bottom: 16rem;
		}
	}

	img {
		position: absolute;
		bottom: -4rem;
		right: 32%;
		min-width: 60%;
		height: 110%;
		@media(max-width: $sw-sm-max) {
			right: 32%;
			height: 100%;
		}
		@media(max-width: $sw-xs-max) {
			display: none;
		}
		@media(max-width: $sw-xx-max) {
			max-width: inherit;
		}
	}
}

.main-banner__title {
	&-wrap {
		display: flex;
		justify-content: flex-end;
		position: relative;
		z-index: 2;
	}

	&-line {
		margin-right: 1.5rem;
		width: 23rem;
		height: 3rem;
		border-bottom: 3px solid $red;
		animation-delay: 4s;
		animation-duration: 2s;
	}

	&-sm {
		font-family: 'Comforta', sans-serif;
		font-size: 2.4rem;
		font-weight: 300;
		color: $black;
		animation-delay: 2s;
		animation-duration: 2s;
		@media(max-width: $sw-xs-max) {
			font-size: 2.2rem;
		}
		@media(max-width: $sw-xx-max) {
			font-size: 2rem;
		}
	}

	&-xl {
		font-family: 'Comforta', sans-serif;
		font-size: 6.5rem;
		font-weight: 400;
		color: $black;
		animation-delay: 3s;
		animation-duration: 2s;
		@media(max-width: $sw-xs-max) {
			font-size: 5.4rem;
		}
		@media(max-width: $sw-xx-max) {
			font-size: 4.4rem;
		}
	}
}