.page-contact {
	padding: 4rem 0 20rem 0;
	&__inner {
		display: flex;
		justify-content: space-between;
		@media(max-width: $sw-xs-max) {
			flex-direction: column;
		}
	}
	@media(max-width: $sw-xs-max) {
		padding: 4rem 0 6rem 0;
	}
}

.contact {
	&__info {
		padding: 5rem;
		width: 49%;
		min-height: 47.5rem;
		border: 1px solid #dddddd;
		@media(max-width: $sw-md-max) {
			padding: 4rem;
		}

		@media(max-width: $sw-sm-max) {
			padding: 3rem;
		}

		@media(max-width: $sw-xs-max) {
			padding: 2rem;
			width: 100%;
			min-height: inherit;
			margin-bottom: 3rem;
		}
		
		.title--black {
			margin-bottom: 1.5rem;
			font-size: 3.2rem;
			line-height: 1.4em;
			@media(max-width: $sw-md-max) {
				font-size: 2.8rem;
			}
			@media(max-width: $sw-md-max) {
				font-size: 2.6rem;
			}
		}

		.subtitle--red {
			margin-bottom: 3rem;
			font-size: 1.6rem;
			@media(max-width: $sw-md-max) {
				font-size: 1.5rem;
			}
		}
		a {
			color: $black;
			font-size: 13px;
			font-weight: 300;
			font-family: 'Roboto';
			letter-spacing: 0.33px;
			transition: color $transition;
			&:hover {
				color: $red;
			}
		}
	}

	&__info-mail,
	&__info-skype,
	&__social {
		position: relative;
		margin-bottom: 2rem;
		padding-left: 6rem;
		display: flex;
		min-height: 4rem;
	}
	&__info-mail {
		flex-direction: column;
		align-items: flex-start;
	}

	&__info-skype {
		align-items: center;
	}

	&__social {
		align-items: center;
		&-item {
			margin-right: 1.8rem;
			.icon {
				font-size: 1.7rem;
				color: #b5b5b5;
				transition: color $transition;
				&:hover {
					color: $black;
				}
			}
		}
	}

	&__item-icon {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 4rem;
		height: 4rem;
		color: $white;
		background-color: $red;
		font-size: 1.8rem;
		font-weight: 500;
	}
	&__form {
		padding: 5rem;
		width: 49%;
		min-height: 47.5rem;
		border: 1px solid #dddddd;
		@media(max-width: $sw-md-max) {
			padding: 3rem;
		}

		@media(max-width: $sw-sm-max) {
			padding: 3rem;
		}

		@media(max-width: $sw-xs-max) {
			padding: 2rem;
			width: 100%;
			min-height: inherit;
		}

		.form-control {
			border-bottom: 4px solid #e2e2e2;
			color: $black;
			&::placeholder {
				color: $black;
			}
		}

		.files {
			flex-shrink: 0;
		}

		.add-icon {
			background-color: $red;
			color: $white;
		}

		label {
			border: $red;
			color: $black;
		}

		.fileList li {
			color: $black;
			background-image: url('../images/useful/bg/file--wh.png');
		}
	}
}