.services-banner {
	padding: 16rem 0 6rem;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 31rem;
		background-image: url('../images/tmp_file/serv-page/bg-layout.png');
		background-size: cover;
		@media(max-width: $sw-xs-max) {
			height: 25rem;
		}
		@media(max-width: $sw-xs-max) {
			height: 17rem;
		}
		@media(max-width: $sw-xx-max) {
			height: 11rem;
		}
	}

	@media(max-width: $sw-xs-max) {
		padding: 3rem 0 3rem;
	}

	&__content {
		display: flex;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		 margin-bottom: 9rem;
		 @media(max-width: $sw-xs-max) {
				margin-bottom: 3rem;
			}
	}

	&__item {
		position: relative;
		padding: 6.2rem 5.5rem;
		width: 35.5rem;
		height: 48rem;
		display: flex;
		align-items: flex-end;
		background-color: $white;
		border: 1px solid #d0d0d0;
		transition: background-color $transition ease, border $transition ease;
		@media(max-width: $sw-md-max) {
			width: 28rem;
			height: 39.4rem;
		}
		@media(max-width: $sw-sm-max) {
			width: 22rem;
			height: 30.5rem;
			padding: 2.5rem 1.5rem;
		}
		@media(max-width: $sw-xs-max) {
			width: 31%;
			height: 25.5rem;
			padding: 2.5rem 1.5rem;
		}
		@media(max-width: 600px) {
			height: 20.5rem;
			padding: 2.5rem 1rem;
		}
		@media(max-width: 550px) {
			height: 18.5rem;
		}

		@media(max-width: $sw-xx-max) {
			width: 32%;
			height: 13rem;
			padding: 2rem 5px;
		}

		&:before {
			content: '';
			position: absolute;
			right: 15%;
			bottom: -5%;
			height: 95%;
			width: 4px;
			display: block;
			background-color: $red;
			transform: scaleY(0);
			transition: transform $transition ease;
		}

		&.active {
			background-color: #feccc5;
			border: 1px solid #feccc5;
			h3 {
				color: $red;
			}
			&:before {
				transform: scaleY(1);
			}
		}
		
		&:hover {
			background-color: #feccc5;
			border: 1px solid #feccc5;
			h3 {
				color: $red;
			}
			&:before {
				transform: scaleY(1);
			}
		}
		
		h3 {
			color: $black;
			font-size: 2.2rem;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 4.8px;
			@media(max-width: $sw-xs-max) {
				font-size: 1.6rem;
				letter-spacing: 3px;
			}
			@media(max-width: 550px) {
				font-size: 1.5rem;
				letter-spacing: 2.7px;
			}
			@media(max-width: $sw-xx-max) {
				font-size: 1.1rem;
				letter-spacing: 1px;
			}
		}
	}
}

.tab-pane:not(.active) {
	display: none;
}