.contact-form {
	padding-top: 5.8rem;
	min-height: 58rem;
	background-color: $white;
	position: relative;
	@media(max-width: $sw-md-max) {
		min-height: 45rem;
	}
	&:before {
		content: '';
		position: absolute;
		bottom: -6%;
		left: calc(50% - 75rem);
		width: 75rem;
		height: 2.2rem;
		background-color: $black;
		@media(max-width: $sw-xs-max) {
			display: none;
		}
	}
	.container {
		position: relative;
	}
	.form--glob {
		position: absolute;
		top: 0;
		left: -15rem;
		width: 33%;
		@media(max-width: $sw-sm-max) {
			left: -10rem;
		}
	}
	.form--mount {
		position: absolute;
		bottom: -3rem;
		left: -6%;
		z-index: 3;
		max-width: 64rem;
		@media(max-width: $sw-md-max) {
			width: 60%;
			bottom: -3px;
		}
		@media(max-width: $sw-sm-max) {
			left: -19%;
		}
		@media(max-width: $sw-xs-max) {
			display: none;
		}
	}
	&__inner {
		display: flex;
		width: 100%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 10% 100%;
		@media(max-width: $sw-xs-max) {
			flex-direction: column;
		}
	}
	
	&__left {
		position: relative;
		padding: 16rem 0;
		width: 30%;
		z-index: 4;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 2;
			margin-left: -17rem;
			background-size: contain;
			background-repeat: no-repeat;
		}
		@media(max-width: $sw-md-max) {
			padding: 12rem 0;
		}
		@media(max-width: $sw-sm-max) {
			width: 33%;
		}
		@media(max-width: $sw-xs-max) {
			width: 100%;
			order: 2;
		}

		@media(max-width: $sw-xs-max) {
			display: none;
		}

		&--circl {
			position: absolute;
			top: 2rem;
			right: 30%;
			width: 12rem;
			height: 12rem;
			border-radius: 50%;
			border: 1px solid #e3e3e3;
		}
	}
	&__descr {
		margin-right: 6.8rem;
		max-width: 25rem;
		@media(max-width: $sw-sm-max) {
			margin-right: 3rem;
		}
		p {
			color: $black;
			font-size: 1.4rem;
			line-height: 1.9em;
			font-weight: 400;
		}
	}

	&__right {
		position: relative;
		padding-top: 7.5rem;
		width: 70%;
		@media(max-width: $sw-md-max) {
			padding-top: 7.5rem;
		}
		@media(max-width: $sw-sm-max) {
			width: 67%;
		}
		@media(max-width: $sw-xs-max) {
			width: 100%;
		}
		@media(max-width: $sw-xx-max) {
			padding-top: 2rem;
		}
		&:before {
			content: '';
			position: absolute;
			top: 38%;
			left: -15%;
			z-index: 3;
			width: 43%;
			height: 2%;
			transform: rotate(-27deg);
			background-color: $black;
			@media(max-width: $sw-sm-max) {
				display: none;
			}
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			right: 17%;
			z-index: 2;
			width: 23%;
			height: 1%;
			transform: rotate(29deg);
			background-color: $black;
			@media(max-width: $sw-sm-max) {
				display: none;
			}
		}
	}

	&__right-inner {
		z-index: 2;
		padding: 5rem 0 7.2rem 26rem;
		min-height: 41rem;
		height: 100%;
		position: relative;
		@media(max-width: $sw-md-max) {
			padding: 5rem 0 7.2rem 22rem;
		}
		@media(max-width: $sw-sm-max) {
			padding: 4rem;
		}
		@media(max-width: $sw-sm-max) {
			padding: 4rem 2rem;
		}
		@media(max-width: $sw-xx-max) {
			padding: 3rem 2rem;
		}
		.bg-line {
			position: absolute;
			right: -13%;
			top: 15%;
			width: 15%;
			height: 1%;
			background-color: $black;
			transform: rotate(27deg);
			@media(max-width: $sw-sm-max) {
				display: none;
			}
			&2 {
				right: -20%;
				top: 57%;
				width: 14%;
				transform: rotate(-34deg);
			}
		}
		&:before {
			content: '';
			position: absolute;
			top: 41%;
			left: 9%;
			z-index: 1;
			width: 23%;
			height: 1%;
			transform: rotate(-32deg);
			background-color: $black;
			@media(max-width: $sw-sm-max) {
				display: none;
			}
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: -37rem;
			bottom: 0;
			left: 0;
			z-index: 0;
			background-color: $red;
			background-image: url('../images/useful/bg/form-right-bg.png');
			background-size: cover;
			background-position: bottom;
			z-index: -1;
			@media(max-width: $sw-xs-max) {
				right: 0;
			}
		}
	}
	&__title {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: -3rem;
		left: 5.7rem;
		bottom: 0;
		z-index: 1;
		transform: rotate(270deg);
		@media(max-width: $sw-sm-max) {
			transform: rotate(0);
			position: static;
		}
		&-inner {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
		span {
			color: $white-font;
			font-size: 4rem;
			line-height: 1.2em;
			letter-spacing: 1.11px;
			font-family: 'Comforta', sans-serif;
			font-weight: 700;
			@media(max-width: $sw-md-max) {
				font-size: 3rem;
			}
		}
	}

	.error {
		color: $white;
		font-size: 1.2rem;
		letter-spacing: 0.5px;
	}
}

.form {
	position: relative;
	z-index: 2;
	&-group {
		margin-bottom: 2.3rem;
	}

	.textarea {
		resize: none;
		height: 8rem;
		&::placeholder {
			padding-top: 3rem;
		}
	}
	.button--white:hover {
		background-color: $black;
	}
}

.form-footer {
	display: flex;
	justify-content: space-between;
	margin-top: 3.2rem;
	@media(max-width: $sw-xs-max) {
		flex-direction: column;
		align-items: flex-start;
	}
}

.files {
	display: flex;
	align-items: center;
	@media(max-width: $sw-md-max) {
		flex-direction: column;
		align-items: flex-start;
	}
	@media(max-width: $sw-xs-max) {
		margin-bottom: 2rem;
		flex-direction: row;
		align-items: center;
	}
	@media(max-width: $sw-xx-max) {
		flex-direction: column;
		align-items: flex-start;
	}
	label {
		display: flex;
		flex-shrink: 0;
		align-items: center;
		color: $white-font;
		font-size: 1.4rem;
		font-family: 'Roboto';
		margin-right: 1rem;
	}

	input {
		opacity: 0;
		height: 0;
		width: 0;
	}

	.fileList {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: 50%;
		
		li {
			position: relative;
			margin: 1px 0;
			padding: 4px 13px 2px 17px;
			max-width: 20rem;
			overflow: hidden;
			background-image: url('../images/useful/bg/file.png');
			background-position: left;
			background-repeat: no-repeat; 
			background-size: 10px;
			color: $white;
			&:before {
				content: 'x';
				position: absolute;
				top: -2px;
				right: 2px;
			}
			@media(max-width: $sw-xx-max) {
				max-width: 20rem;
			}
		}
		a {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.add-icon {
	position: relative;
	margin-right: 1rem;
	display: inline-block;
	width: 3.7rem;
	height: 3.7rem;
	border: 2px solid $white;
	color: $white;
	font-size: 1.1rem;
	.icon {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
	}
}
