.slick-arrow-wrap {
	position: absolute;
	z-index: 2;
	.arrow-prev,
	.arrow-next {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 5.7rem;
		height: 5.3rem;
		color: $white;
		font-size: 1.2rem;
		background-color: $red;
		cursor: pointer;
		transition: background-color $transition ease;
		&:hover,
		&:focus {
			background-color: $black;
		}
	}
	.arrow-next {
		margin-top: 1px;
		transform: rotate(180deg);
	}
}