.about-me {
	padding-top: 10rem;
	background-color: $white;
	position: relative;
	@media(max-width: $sw-md-max) {
		padding-top: 3rem;
	}
	@media(max-width: $sw-xx-max) {
		padding-top: 0;
	}
	.about-me--left {
		position: absolute;
		// top: 0;
		bottom: 10rem;
		left: -34%;
		z-index: 3;
		width: 40%;
		height: 90%;
		@media(max-width: $sw-md-max) {
			height: 70%;
			bottom: 3.8rem;
		}
		@media(max-width: $sw-sm-max) {
			display: none;
		}
	}
	.about-me--bottom {
		position: absolute;
		left: 10%;
		bottom: 0;
		z-index: 2;
		width: 33%;
		@media(max-width: $sw-md-max) {
			display: none;
		}
	}
	&__wrap {
		position: relative;
		display: flex;
		@media(max-width: $sw-xs-max) {
			flex-direction: column;
		}
	}
	&__left {
		margin-right: 8rem;
		width: 60%;
		z-index: 2;
		@media(max-width: $sw-md-max) {
			width: 60%;
			margin-right: 5rem;
		}
		@media(max-width: $sw-sm-max) {
			width: 50%;
			margin-right: 3rem;
		}
		@media(max-width: $sw-xs-max) {
			order: 2;
			width: 100%;
		}
	}
	&__right {
		position: relative;
		z-index: 1;
		padding-left: 8rem;
		width: 40%;
		@media(max-width: $sw-md-max) {
			padding-left: 5rem;
		}
		@media(max-width: $sw-sm-max) {
			width: 50%;
			padding-left: 5rem;
		}
		@media(max-width: $sw-xs-max) {
			margin-bottom: 3rem;
			padding-left: 0;
			width: 100%;
		}
		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: -32rem;
			bottom: 0;
			left: 0;
			z-index: -1;
			background-color: #313131;
			@media(max-width: $sw-xs-max) {
				left: -5rem;
			}
		}
	}
	&__quote {
		margin-bottom: 3.6rem;
		display: flex;
		@media(max-width: $sw-sm-max) {
			flex-direction: column;
			// margin-bottom: 0;
		}
		&-text {
			@media(max-width: $sw-sm-max) {
				order: 2;
				margin-top: 5rem;
			}
			p {
				margin-top: 2rem;
				margin-right: 6rem;
				font-size: 1.3rem;
				color: $black;
				font-style: italic;
				font-weight: 300;
				@media(max-width: $sw-sm-max) {
					margin-right: 1rem;
				}
			}
		}
		&-author {
			width: 21.6rem;
			height: 21.6rem;
			flex-shrink: 0;
			@media(max-width: $sw-xs-max) {
				margin: auto;
			}
			img {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				max-width: 100%;
				max-height: 100%;
				width: 95%;
				margin: auto;
				border-radius: 50%;
			}
		}
	}
	&__skills {
		position: relative;
		padding : 6rem 0 8rem;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: -11rem;
			bottom: 0;
			left: -37rem;
			background-color: $red;
			@media(max-width: $sw-md-max) {
				right: -8rem;
			}
			@media(max-width: $sw-sm-max) {
				right: -6rem;
			}
		}
		h3 {
			position: relative;
			z-index: 2;
			font-size: 1.6rem;
			font-weight: 500;
			color: $white;
			text-transform: uppercase;
			letter-spacing: 3px;
		}

		li {
			position: relative;
			display: flex;
			justify-content: space-between;
			padding: 2.8rem 0 1rem;
			border-bottom: 1px solid rgba(255, 255, 255, 0.3);
			span {
				line-height: 1.7em;
				font-size: 1.4rem;
				color: $white;
				letter-spacing: 0.35px;
			}
		}
		.creativity:after,
		.problem-solving:after,
		.detail-oriented:after,
		.communication:after {
			content: '';
			position: absolute;
			bottom: -4px;
			height: 7px;
			left: 0;
			display: block;
			background-image: linear-gradient(to right, #de2432 0%, #ffffff 100%);
		}
		.creativity:after {
			width: 85%;
			transform-origin: 0 50%;
			transition: transform 2s ease;
			transition-delay: 0.5s;
			transform: scaleX(0);
		}
		.problem-solving:after {
			width: 50%;
			transform-origin: 0 50%;
			transition: transform 2s ease;
			transition-delay: 1s;
			transform: scaleX(0);
		}
		.detail-oriented:after {
			width: 75%;
			transform-origin: 0 50%;
			transition: transform 2s ease;
			transition-delay: 1.5s;
			transform: scaleX(0);
		}
		.communication:after {
			width: 55%;
			transform-origin: 0 50%;
			transition: transform 2s ease;
			transition-delay: 2s;
			transform: scaleX(0);
		}

		.animated {
			.creativity:after {
				transform: scaleX(1);
			}
			.problem-solving:after {
				transform: scaleX(1);;
			}
			.detail-oriented:after {
				transform: scaleX(1);
			}
			.communication:after {
				transform: scaleX(1);
			}
		}
	}
	&__biographi {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding: 9.1rem 0;
		@media(max-width: $sw-md-max) {
			padding: 5.1rem 0;
		}
		@media(max-width: $sw-xx-max) {
			padding: 5.1rem 0;
		}
		.button--white{
			position: relative;
			z-index: 1;
		}
		h2 {
			margin-bottom: 1rem;
			z-index: 2;
			flex-shrink: 0;
		}
		h3 {
			margin-bottom: 3rem;
			color: $white-font;
			font-size: 1.6rem;
			font-weight: 500;
			letter-spacing: 3.2px; 
			z-index: 2;
		}
		p {
			margin-bottom: 6rem;
			font-size: 1.3rem;
			color: $white-font;
			font-weight: 300;
			line-height: 2em;
			z-index: 2;
			width: 100%;
			@media(max-width: $sw-md-max) {
				margin-bottom: 3rem;
			}
			// @media(max-width: $sw-xx-max) {
			// 	margin-bottom: 3rem;
			// }
		}
		.button {
			align-self: flex-start;
		}
		.rectangle {
				width: 270px;
				height: 270px;
			&-bg {
				position: absolute;
				top: 5%;
				right: -40%;
				opacity: 0.18;
				z-index: 0;
			}
		}
		.circle-bg {
			position: absolute;
			bottom: 4rem;
			left: 21rem;
			width: 11rem;
			height: 11rem;
			opacity: 0.18;
			border-radius: 50%;
			border: 1px solid #e3e3e3;
		}
	}
}

.rectangle {
	position: relative;
	border: 1px solid #e3e3e3;
	&:before,
	&:after {
		content: '';
		position: absolute;
		right: 50%;
		transform: translateX(50%);
		width: 8px;
		height: 8px;
		border: 1px solid #e3e3e3;
	}
	&:before {
		top: -4px;
	}
	&:after {
		bottom: -4px;
	}
	&--circle {
		height: 100%;
		border-radius: 50%;
		border: 1px solid #e3e3e3;
		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 8px;
			height: 8px;
			border: 1px solid #e3e3e3;
		}
		&:before {
			left: -4px;
		}
		&:after {
			right: -4px;
		}
	}
}