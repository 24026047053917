.footer {
	bottom: 0;
	background-color: #2a2a2a;
	flex-shrink: 0;
	position: absolute;
    bottom: 0;
    width: 100%;

	&-main {
		padding: 5.5rem 0 8rem;
		display: flex;
		align-items: stretch;
		max-width: 100%;
		@media(max-width: $sw-sm-max) {
			flex-wrap: wrap;
			padding: 4rem 0;
		}
	}

	&__menu {
		padding-right: 12rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 100%;
		text-transform: uppercase;
		border-right: 1px solid $gray-font;
		@media(max-width: $sw-md-max) {
			padding-right: 10rem;
		}
		@media(max-width: $sw-sm-max) {
			order: 2;
			width: 50%;
			text-align: right;
		}
		@media(max-width: $sw-xs-max) {
			padding-right: 3rem;
		}
		@media(max-width: $sw-xx-max) {
			padding-right: 3rem;
		}
		span {
			font-size: 1.8rem;
			font-weight: 700;
			color: $white-font;
			letter-spacing: 3.6px;
			font-family: 'Oswald', sans-serif;
		}
		&-items {
			font-size: 1.5rem;
			a {
				display: block;
				height: 100%;
				color: $gray-font;
				transition: color $transition ease;
				&:hover,
				&.active,
				&:focus {
					color: $red;
				}
			}
		}
		&-items:first-child {
			margin-top: 1rem;
		}
	}

	&__services {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0 12rem;
		border-right: 1px solid $gray-font;
		@media(max-width: $sw-md-max) {
			padding: 0 10rem;
		}
		@media(max-width: $sw-sm-max) {
			order: 3;
			border: none;
			padding-right: 0;
			width: 50%;
		}
		@media(max-width: $sw-xs-max) {
			padding-left: 3rem;
		}
		@media(max-width: $sw-xx-max) {
			padding-left: 3rem;
		}
		span {
			font-size: 1.8rem;
			font-weight: 700;
			color: $white-font;
			letter-spacing: 3.6px;
			text-transform: uppercase;
			font-family: 'Oswald', sans-serif;
		}

		&-items {
			text-transform: capitalize;
			font-size: 1.5rem;
			line-height: 1.75em;
			a {
				display: block;
				height: 100%;
				color: $gray-font;
				color: #c5c5c5;
				transition: color $transition ease;
				&:hover,
				&:focus {
					color: $red;
				}
			}

		}
		&-items:first-child {
			margin-top: 1rem;
		}
	}

	.footer__info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-end;
		padding-left: 10rem;
		width: 43%;
		@media(max-width: $sw-md-max) {
			padding-left: 5rem;
		}

		@media(max-width: $sw-sm-max) {
			align-items: center;
			order: 1;
			padding: 0;
			width: 100%;
		}
		@media(max-width: $sw-xs-max) {
			margin-bottom: 3rem;
		}

		.logo--sm {
			@media(max-width: $sw-md-max) {
				margin-bottom: 1rem;
			}
		}

		.icon-logo {
			font-size: 5.1rem;
			color: #c5c5c5;
			@media(max-width: $sw-md-max) {
				font-size: 4.1rem;
			}
		}
		.footer__info-descr {
			font-size: 1.4rem;
			color: $gray-font;
			line-height: 22px;
			width: 100%;
			@media(max-width: $sw-md-max) {
				margin-bottom: 1rem;
			}
			@media(max-width: $sw-sm-max) {
				margin: 2rem 0;
			}
			@media(max-width: $sw-xs-max) {
				margin-bottom: 1rem;
			}
		}
	}
	.social {
		display: flex;
		p {
			font-size: 1.4rem;
			color: $gray-font;
		}
		&-box {

			a {
				margin-left: 1.5rem;
			}

			.icon {
				font-size: 1.8rem;
				transition: color $transition ease;
				&:hover {
					color: $red;
				}
			}
		}
	}

	&__copyrights {
		border-top: 1px solid #4c4c4c;
		.container {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 6.5rem;
			font-size: 1.3rem;
			color: $gray-font;
			@media(max-width: $sw-xs-max) {
				font-size: 1.2rem;
			}
			p {
				@media(max-width: $sw-xx-max) {
					max-width: 60%;
					text-align: center;
				}
			}
		}


		.button-top {
			position: absolute;
			top: 0;
			right: 1.5rem;
			width: 4.6rem;
			height: 4.6rem;
			background-color: $red;
			display: flex;
			justify-content: center;
			align-items: center;
			.icon {
				color: $white;
				font-size: 1.2rem;
				transform: rotate(90deg);
			}
		}
	}
}