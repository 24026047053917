.blog {
	padding-bottom: 10rem;
	&-inner {
		padding-top: 8rem;
		border-bottom: 1px solid #e2e2e2;
		@media(max-width: $sw-xx-max) {
			padding-top: 4rem;
		}
	}
	&-article {
		margin-bottom: 11rem;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		@media(max-width: $sw-sm-max) {
			flex-direction: column;
		}
		@media(max-width: $sw-xs-max) {
			margin-bottom: 4rem;
		}
		&__img {
			width: 48.5%;
			&:hover {
				img {
					transform: scale(1.05);
				}
			}
			@media(max-width: $sw-sm-max) {
				margin-bottom: 3rem;
				width: 100%;
			}
			@media(max-width: $sw-xx-max) {
				margin-bottom: 2rem;
			}
			img {
				transition: transform $transition;
				width: 100%;
			}
		}
		&__info {
			width: 48.5%;
			@media(max-width: $sw-sm-max) {
				width: 100%;
			}
			&-title {
				margin-bottom: 1.6rem;
				font-family: Comforta, sans-serif;
				font-size: 2.4rem;
				font-weight: 700;
				color: $black;
				letter-spacing: 0.22px;
				transition: color $transition;
				&:hover {
					color: $red;
				}
				@media(max-width: $sw-xx-max) {
					margin-bottom: 3px;
					font-size: 2rem;
				}
			}
			.p--bl {
				margin-bottom: 4.8rem;
				@media(max-width: $sw-md-max) {
					margin-bottom: 3rem;
				}
				@media(max-width: $sw-xx-max) {
					margin-bottom: 2rem;
				}
			}

			.button {
				width: 13rem;
			}
		}
	}

	&-navigation, .wp-pagenavi {
		display: flex;
		& .pages{
			display: none;
		}
		&__item, > span, > a {
			border:none;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 4rem;
			height: 4rem;
			color: $red;
			font-size: 1.2rem;
			font-weight: 700;
			transition: color $transition, background-color $transition;
			&.active, &.current {
				    margin: 0;
				color: $white;
				background-color: $red;
			}
			&:hover {
				color: $white;
				background-color: $red;
			}
			&--arr {
				font-size: 1.6rem;
			}
		}
	}
}