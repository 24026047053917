.c-preloader {
	position: fixed;
	z-index: 1100;
	background-color: #FFF;
	width: 100vw;
	height: 100vh;
}
.c-preloader.hidden{
	display: none;
}
.c-preloader.hidden.loading{
	opacity: 0.4;
	display: block;
}
.loader {
	position: absolute;
	top: calc(50% - 10vmin);
	left: calc(50% - 10vmin);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 10vmin;
	width: 10vmin;
	border: 6px solid #fff;
	border: {
		top-color: #b51622;
		right-color: #b51622;
		radius: 100%;
	}
	animation: spin 3800ms infinite linear;
	
	@media(max-width: $sw-xs-max) {
		height: 20vmin;
		width: 20vmin;
		border: {
			top-color: #b51622;
			right-color: #b51622;
			radius: 100%;
		}
	}
	&-circle {
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc(10vmin - 20px);
		height: calc(10vmin - 20px);
		border: 6px solid #fff;
		border: {
			right-color: #2a2a2a;
			bottom-color: #2a2a2a;
			radius: 100%;
		}
		animation: spin 4800ms infinite linear;
		@media(max-width: $sw-xs-max) {
			width: calc(20vmin - 20px);
			height: calc(20vmin - 20px);
			border: {
				right-color: #2a2a2a;
				bottom-color: #2a2a2a;
				radius: 100%;
			}
		}
		&2 {
			display: flex;
			align-items: center;
			justify-content: center;
			width: calc(10vmin - 40px);
			height: calc(10vmin - 40px);
			border: 6px solid #fff;
			border: {
				right-color: #b51622;
				top-color: #b51622;
				radius: 100%;
			}
			animation: spin 5800ms infinite linear;
			@media(max-width: $sw-xs-max) {
				width: calc(20vmin - 40px);
				height: calc(20vmin - 40px);
				border: {
					right-color: #b51622;
					top-color: #b51622;
					radius: 100%;
				}
			}
		}
		&3 {
			width: calc(10vmin - 60px);
			height: calc(10vmin - 60px);
			border: 6px solid #fff;
			border: {
				bottom-color: #2a2a2a;
				left-color: #2a2a2a;
				radius: 100%;
			}
			animation: spin 6800ms infinite linear;
			@media(max-width: $sw-xs-max) {
				width: calc(20vmin - 60px);
				height: calc(20vmin - 60px);
				border: {
					bottom-color: #2a2a2a;
					left-color: #2a2a2a;
					radius: 100%;
				}
			}
		}
	}
}

@keyframes "spin" {
	from { transform: rotate(0deg); }
	to { transform: rotate(359deg); }
}