.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
  
  /* ==========================================
  Single-colored icons can be modified like so:
  .icon-name {
    font-size: 32px;
    color: red;
  }
  ========================================== */

.icon {
  &-burger-menu {
    font-size: 1.4rem;
    color: $white;
  }
  &-search {
    font-size: 2.4rem;
    color: $black;
  }
  &-arrow-down {
    font-size: 7px;
  }
  &-inst {
    color: $gray-font;
  }
  &-pint {
    color: $gray-font;
  }
  &-face {
    color: $gray-font;
  }
  &-be {
    color: $gray-font;
  }
  &-logo {
    width: 4.957547169811321em;
  }
}
  