.services-what-i-do {
	padding-bottom: 1rem;
	.container {
		padding: 8rem 1.5rem 9rem;
		border-top: 1px solid #b4b4b4;
		@media(max-width: $sw-xs-max) {
			padding: 5rem 1.5rem;
		}
	}
	@media(max-width: $sw-xs-max) {
		padding-bottom: 0;
	}
}

.services-slider {
	overflow: hidden;

	&__wrap {
		position: relative;
		// margin-top: 8rem;
		@media(max-width: $sw-xs-max) {
			margin-top: 0;
		}
		
		.title--black {
			margin-bottom: 2rem;
		}
		
		.slick-arrow-wrap {
			top: 0;
			right: 0;
			left: auto;
			bottom: auto;
			display: flex;
			flex-direction: row;
			@media(max-width: $sw-xs-max) {
				top: 35rem;
			}
		}

		.services-next {
			margin-top: 0;
			margin-left: 1px;
		}

		.button {
			position: absolute;
			bottom: 0;
			left: 0;
			top: auto;
			right: auto;
		}
	}


	&__item {
		display: inline-block;
	}

	
	&__item-inner {
		display: flex;
		@media(max-width: $sw-xs-max) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__text {
		margin-right: 5rem;
		padding-bottom: 7rem;
		width: 70%;
		@media(max-width: $sw-sm-max) {
			width: 60%;
		}
		@media(max-width: $sw-xs-max) {
			width: 100%;
			margin-right: 0;
			padding-bottom: 3rem;
			order: 2;
		}
		
		
		h3 {
			margin-bottom: 3rem;
			color: #b51622;
			font-size: 1.5rem;
			font-family: 'Oswald', sans-serif;
			letter-spacing: 3px;
			text-transform: uppercase;
			font-weight: 500;
		}

		.p--bl {
			margin-bottom: 2rem;
		}
	}

	&__img {
		position: relative;
		width: 30%;
		min-height: 28rem;
		@media(max-width: $sw-sm-max) {
			width: 40%;
		}
		@media(max-width: $sw-xs-max) {
			width: 30rem;
			margin-bottom: 7rem;
		}
		img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			max-width: 100%;
			max-height: 100%;
			width: 100%;
			margin: auto;
		}
	}
}