button {
	// button reset
	display: inline-block;
	margin: 0;
	padding: 0;
	border: 0;
	background-color: transparent;
	cursor: pointer;
}

button:focus {
	// outline: none;
	// overflow: hidden;
}
.btn{
	display: inline-block;
	// height: 51px;
	border-radius: 11px;
	font-weight: 400;
	line-height: 48px;
	padding: 0 28px;
	border: 1px solid transparent;
	background-color: transparent;
	color: inherit;
	transition: all 300ms ease;
	&:hover{
		
		// border-color: @White40;
		// background-color: @White80;
		// color: @CapeCod;
	}
	&:focus,
	&:active{
		// border-color: @White40;
		// background-color: @Concrete;
		// color: @CapeCod;
	}
	// colors
	&_white{
	}
	// states
	// a&{
	//     text-decoration: none;
	// }
	&_icon{
	}
	// sizes
	&_md{
	}
	&_sm{
	}
	&_xs{
	}
}

.button {
	padding: 0 2.4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 5.3rem;
	line-height: 5.3rem;
	color: $white-font;
	font-size: 1.2rem;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 2.4px;
	background-color: $red;
	transition: all $transition ease;
	@media(max-width: $sw-xs-max) {
		height: 3.5rem;
		padding: 0 1.5rem;
		line-height: 3.5rem;
	}
	@media(max-width: $sw-xx-max) {
		padding: 0 1rem;
		font-size: 1rem;
	}
	&:hover {
		background-color:$black;
		color: $white-font;
	}
	&--lg {
		width: 18rem;
		@media(max-width: $sw-xs-max) {
			width: 15rem;
		}
	}
	&--black {
		background-color: $black;
		&:hover {
			background-color:$red;
			color: $white-font;
		}
	}
	&--white {
		background-color: $white;
		color: $red;
		&:hover {
			background-color:$red;
			color: $white-font;
		}
	}
}