.services-questions {
	position: relative;
	// z-index: 1;
	padding: 8.5rem 0;
	background-image: url('../images/tmp_file/serv-page/serv-bg2.png');
	/* background-size: cover; */

	&__info {
		h2 {
			width: 100%;
			text-align: center;
			margin-bottom: 4.5rem;
		}
	}

	.accordion {
		column-count: 2;
		column-fill: auto;
		column-gap: 3rem;
		min-height: 27.2rem;
		@media(max-width: $sw-sm-max) {
			column-count: 1;
		}
	}

	.card {
		margin-bottom: 1rem;
		display: inline-block;
		width: 100%;
	}

	.card-header {
		background-color: $red;
		button {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 25px 10px 25px;
			width: 100%;
			min-height: 5.8rem;
			color: $white;
			font-size: 14px;
			font-family: 'Roboto';
			letter-spacing: 0.35px;
			line-height: 1.7em;
			white-space: normal;
			text-align: left;
			.icon-plus {
				flex-shrink: 0;
				margin-left: 1.5rem;
				display: none;
				font-size: 10px;
				
				@media(max-width: $sw-sm-max) {
					margin-left: 2rem;
				}
			}

			.icon-minus {
				flex-shrink: 0;
				margin-left: 1.5rem;
				width: 10px;
				height: 2px;
				background-color: $white;
				@media(max-width: $sw-sm-max) {
					margin-left: 2rem;
				}
			}

			&.collapsed {
				.icon-plus {
					display: block;
				}
				.icon-minus {
					display: none;
				}
			}
		}
	}

	.show {
		border-top: 1px solid rgba(254, 204, 197, 0.4);
	}

	.card-body {
		padding: 20px 25px;
		background-color: $red;
		.p--wh {
			margin: 0;
		}
	}
}