.work-direct {
	margin-top: -10rem;
	background-color: transparent;
	@media(max-width: $sw-xs-max) {
		margin-top: 0;
		margin-bottom: 5rem;
	}
	&-wrap {
		display: flex;
		flex-wrap: wrap;
		@media(max-width: $sw-md-max) {
			justify-content: flex-end;
		}
	}
	&__info-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@media(max-width: $sw-md-max) {
			justify-content: flex-start;
		}
		@media(max-width: $sw-xs-max) {
			justify-content: space-between;
		}
	}
	&__info {
		padding-top: 20rem;
		width: 60%;
		@media(max-width: $sw-md-max) {
			padding-top: 15rem;
			margin-top: -5rem;
			width: 60%;
		}
		@media(max-width: $sw-sm-max) {
			padding-top: 10rem;
			margin-top: 0;
		}
		@media(max-width: $sw-xs-max) {
			padding-top: 5rem;
			margin-top: 0;
			width: 100%;
			order: 2;
		}
		.title--black {
			position: relative;
			margin-bottom: 5rem;
			display: inline-block;
			@media(max-width: $sw-xs-max) {
				margin-bottom: 3rem;
			}

		}

		&-item {
			width: 26%;
			margin-bottom: 5.5rem;
			// padding-right: 7rem;
			@media(max-width: $sw-md-max) {
				width: 30%;
				margin-right: 3%;
				margin-bottom: 3.5rem;
			}
			@media(max-width: $sw-xs-max) {
				width: 45%;
				&:nth-child(2) {
					margin-right: 0;
				}
			}
			@media(max-width: $sw-xx-max) {
				margin-right: 0;
				margin-bottom: 3rem;
				width: 48%;
			}

			&:hover {
				cursor: pointer;
				.work-direct__info-img-wrap {
					border-color: transparent;
					background-color: $red;
				}
				.icon {
					color: $white;
				}
			}
			h3 {
				margin-bottom: 1rem;
				color: $red;
				font-size: 1.5rem;
				font-family: 'Oswald', sans-serif;
				letter-spacing: 3px;
				text-transform: uppercase;
				font-weight: 500;
			}
			p {
				font-size: 1.3rem;
				font-weight: 300;
				color: $black;
			}
		}

		&-img-wrap {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 3rem;
			width:  5.7rem;
			height: 5.7rem;
			border: 1px solid $black;
			border-radius: 50%;
			transition: all $transition ease;
			@media(max-width: $sw-md-max) {
				margin-bottom: 2rem;
			}
			@media(max-width: $sw-xx-max) {
				margin-bottom: 2rem;
			}
			.icon {
				font-size: 3.5rem;
				color: $black;
				transition: all $transition ease;
			}
		}
	}

	&__slider-wrap {
		position: relative;
		z-index: 1;
		padding-left: 5.7rem;
		width: 40%;
		@media(max-width: $sw-md-max) {
			width: 40%;
		}
		@media(max-width: $sw-xs-max) {
			width: 100%;
			order: 2;
		}
		.slick-arrow-wrap {
				top: 0;
				left: 0;
		}
		img {
			width: 100%;
		}
		.button {
			margin-left: auto;
		}
	}

	&__slider {
		margin-bottom: 3rem;
		overflow: hidden;
	}
	&__slide {
		display: inline-block;
	}
}

.title--black {
	font-size: 4rem;
	color: $black;
	font-weight: 700;
	font-family: 'Comforta', sans-serif;
	text-transform: uppercase;
	@media(max-width: $sw-md-max) {
		font-size: 3rem;
	}
	@media(max-width: $sw-xx-max) {
		font-size: 2.8rem;
	}
}

.title--white {
	position: relative;
	display: inline-block;
	font-size: 4rem;
	color: $white;
	font-weight: 700;
	font-family: 'Comforta', sans-serif;
	text-transform: uppercase;
	@media(max-width: $sw-md-max) {
		font-size: 3rem;
	}
	@media(max-width: $sw-xx-max) {
		font-size: 2.8rem;
	}
}

.line-after:after {
	content: '';
	position: absolute;
	top: 50%;
	right: -10.4rem;
	transform: translateY(-50%);
	display: block;
	width: 9.4rem;
	height: 4px;
	background-color: #e4e4e4;
}

.line-before:before {
	content: '';
	position: absolute;
	top: 50%;
	left: -10.4rem;
	transform: translateY(-50%);
	display: block;
	width: 9.4rem;
	height: 4px;
	background-color: $red;
}