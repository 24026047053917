.cooperation {
	padding: 6rem 0 10rem;
	@media(max-width: $sw-xs-max) {
		padding: 4rem 0 4rem;
	}
	&__info {
		h3 {
			margin-bottom: 3rem;
		}
	}
	&__slider-wrap {
		display: flex;
		align-items: stretch;
		min-height: 47rem;
	}
	&__slider-left  {
		position: relative;
		z-index: 1;
		padding: 3.5rem 3rem;
		width: 30%;
		@media(max-width: $sw-sm-max) {
			padding: 2rem;
			width: 35%;
		}
		@media(max-width: $sw-xs-max) {
			display: none;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 65%;
			left: 0;
			background-image: linear-gradient(to bottom, #171717 0%, rgba(23, 23, 23, 0) 100%);
		}
		img {
			position: absolute;
			z-index: 0;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		p {
			position: relative;
			z-index: 2;
		} 
	}

	&__slider-right {
		position: relative;
		padding: 1rem 8rem;
		width: 70%;
		@media(max-width: $sw-sm-max) {
			padding: 1rem 7rem 1rem 5rem;
			width: 65%;
		}
		@media(max-width: $sw-xs-max) {
			width: 100%;
		}
		@media(max-width: $sw-xx-max) {
			padding: 1rem 6.2rem 1rem 0;
		}
		.slick-arrow-wrap {
			bottom: 0;
			right: 0;
			@media(max-width: $sw-xs-max) {
				bottom: 40%;			
			}
		}
	}

	&__slider {
		overflow: hidden;
		&-item {
			display: inline-block;
			// display: flex;
			// flex-direction: column;
			// align-items: center;
			.cooperation__slider-text {
				position: relative;
				font-family: 'Roboto';
			}
			.cooperation__slider-text:after {
				content: '';
				position: absolute;
				left: 0rem;
				top: -4.5rem;
				width: 3.3rem;
				height: 2.9rem;
				background-image: url('../images/tmp_file/who-i-am/quote.png');
			}
		}
		&-img {
			margin: auto;
			margin-bottom: 3rem;
			border-radius: 50%;
			width: 12rem;
			height: 12rem;
		}
		&-name {
			margin-bottom: 2rem;
			color: $black;
			font-size: 18px;
			font-weight: 500;
			letter-spacing: 3.6px;
			text-align: center;
		}
		&-position {
			margin-bottom: 2rem;
			font-size: 18px;
			font-weight: 400;
			color: $red;
			letter-spacing: 1.8px;
			text-align: center;
		}
	}
}