// Custom Color system
$scorpion: #585858;
$black: #252525;
$white: #fff;
$red: #b51622;
$gray-border: #e2e2e2;
$white-font: #fffefe;
$gray-font: #c5c5c5;
// Options
$transition: 0.3s;
// Spacing
// Body
$body-bg:       $white;
$body-color:    $scorpion;
// Links
// Style anchor elements.
$link-decoration:       underline;
$link-hover-decoration: none;
// Grid breakpoints
$sw-xx-min:0;
$sw-xs-min:480px;
$sw-sm-min:768px;
$sw-md-min:992px;
$sw-lg-min:1200px;

$sw-xx-max:$sw-xs-min - 1px;
$sw-xs-max:$sw-sm-min - 1px;
$sw-sm-max:$sw-md-min - 1px;
$sw-md-max:$sw-lg-min - 1px;

$grid-breakpoints: (
	xs: $sw-xs-min,
	sm: $sw-sm-min,
	md: $sw-md-min,
	lg: $sw-lg-min,
);
// Grid containers
.container {
	@media(min-width: $sw-xx-min) {
		max-width: 100% !important;
	}
	@media(min-width: $sw-sm-min) {
		max-width: 720px !important;
	}
	@media(min-width: $sw-md-min) {
		max-width: 920px !important;
	}
	@media(min-width: $sw-lg-min) {
		// padding-right: 0!important;
		// padding-left: 0!important;
		max-width: 1200px !important;
	}
}
// Grid columns
$grid-columns:               24;
$grid-gutter-width-base:     30px;
$grid-gutter-width-device:   20px;
$grid-gutter-widths: (
	xs: $grid-gutter-width-device,
	sm: $grid-gutter-width-base,
	md: $grid-gutter-width-base,
	lg: $grid-gutter-width-base
);