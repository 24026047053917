.last-post {
	padding: 18rem 0 16.5rem;
	background-color: $white;
	@media(max-width: $sw-md-max) {
		padding: 12rem 0 6.5rem;
	}
	@media(max-width: $sw-xs-max) {
		padding: 5rem 0 8rem;
	}

	&__info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		@media(max-width: $sw-xs-max) {
			margin-bottom: 4rem;
		}

		&-slider {
			.slick-list {
				overflow: hidden;
			}
			&-item {
				display: inline-block;
			}
		}

		&-title {
			color: $black;
			font-size: 4rem;
			font-weight: 700;
			letter-spacing: 0.9px;
			font-family: 'Comforta', sans-serif;
		}
		&-title-descr {
			margin-bottom: 2rem;
			font-size: 1.4rem;
			color: $black;
		}
		&-subtitle {
			margin-bottom: 1rem;
			font-size: 2rem;
			color: $red;
			font-weight: 500;
			line-height: 1.4em;
			letter-spacing: 4px;
			width: 100%;
			transition: color $transition ease;
			&:hover {
				color: $black;
			}
		}
		&-date {
			margin-bottom: 2.2rem;
			font-size: 1.6rem;
			color: $black;
			letter-spacing: -0.16px;
			font-family: 'Work Sans', sans-serif;
		}
		&-descr {
			margin-bottom: 4.5rem;
			font-size: 1.4rem;
			line-height: 2.2rem;
			color: $black;
			width: 100%;
			@media(max-width: $sw-xs-max) {
				margin-bottom: 1.5rem;
			}
		}
	}

	&__slider-wrap {
		position: relative;
		padding-left: 5.7rem;
		@media(max-width: $sw-md-max) {
			padding-left: 0;
		}
		.slick-arrow-wrap {
			top: 0;
			left: 0;
			@media(max-width: $sw-md-max) {
				top: auto;
				bottom: -5.3rem;
				left: auto;
				right: 0;
				display: flex;
			}
			@media(max-width: $sw-xs-max) {
				top: -5.3rem;
				left: auto;
				right: 0;
			}
			.arrow-next {
				@media(max-width: $sw-md-max) {
					margin-top: 0;
					margin-left: 1px;
				}
			}
		}
		.slick-list {
			overflow: hidden;
		}

	}
	&__slide {
		display: inline-block;
		img {
			@media(max-width: $sw-xs-max) {
				width: 100%;
			}
		}
	}
}