.blog-banner {
	padding: 10rem 0 3rem;
	background-image: url('../images/tmp_file/blog-page/bg-layout.png');
	background-size: cover;
	@media(max-width: $sw-xs-max) {
		padding: 3rem 0 3rem;
	}
	&__content {
		max-width: 86rem;
	}
	&__title-wrap {
		position: relative;
		z-index: 1;
		padding: 4.5rem;
		@media(max-width: $sw-xs-max) {
			padding: 2rem;
		}
		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: #FFF;
			opacity: 0.9;
		}
	}

	&__autor-wrap {
		padding: 11px 4.5rem;
		background-color: $red;
	}
}

.banner__title {
	color: $black;
	font-family: 'Comforta', sans-serif;
	font-size: 28px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.99px;
	@media(max-width: $sw-xs-max) {
		font-size: 20px;
	}
}

.banner__title--cap {
	text-transform: none;
}

.banner__autor {
	color: $white;
	font-size: 1.2rem;
	line-height: 1.1em;
	font-weight: 500;
	letter-spacing: 2.04px;
}