html {
	height: 100%;
	font-size: 10px;
}

body {
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100%;
	letter-spacing: .015rem;
	font-family: 'Oswald', sans-serif;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

li {
	margin: 0;
	padding: 0;
	font-family: 'Roboto';
}

a {
	text-decoration: none;
}

p {
	margin: 0;
	font-family: 'Roboto';
}

img{
	max-width: 100%;
	height: auto;
}

.fadeInUp {
	animation-duration: 1s;
}

.subtitle--black {
	font-size: 24px;
	font-weight: 500;
	text-transform: uppercase;
	color: $black;
	letter-spacing: 4.8px;
	@media(max-width: $sw-xx-max) {
		font-size: 20px;
	}
}

.subtitle--red {
	font-size: 24px;
	font-weight: 500;
	text-transform: uppercase;
	color: $red;
	letter-spacing: 4.8px;
	@media(max-width: $sw-xx-max) {
		font-size: 20px;
	}
}

.subtitle--white {
	font-size: 24px;
	font-weight: 500;
	text-transform: uppercase;
	color: $white;
	letter-spacing: 4.8px;
	@media(max-width: $sw-xx-max) {
		font-size: 20px;
	}
}

.title-data {
	margin-bottom: 1.6rem;
	font-size: 1.6rem;
	color: $red;
	font-weight: 500;
	letter-spacing: 2.72px;
	@media(max-width: $sw-xx-max) {
		font-size: 1.2rem;
		letter-spacing: 2px;
	}
	
}

.p-about-me__quote {
	margin: auto;
	padding: 4rem 0 1.3rem;
	max-width: 720px;
	.author {
		text-align: right;
	}
	p {
		font-size: 16px;
		color: $black;
		line-height: 2.6em;
		letter-spacing: 0.4px;
		text-align: center;
		font-style: italic;
	}
}

.p--bl, .tab-pane p, .services-slider__text p, .services-end-text p {
	margin-bottom: 3.5rem;
	font-size: 13px;
	color: $black;
	line-height: 1.5em;
	letter-spacing: 0.4px;
	text-align: center;
	text-align: left;
	@media(max-width: $sw-xs-max) {
		margin-bottom: 2rem;
	}
}

.p--wh {
	margin-bottom: 3.5rem;
	font-size: 13px;
	color: $white;
	line-height: 1.5em;
	// font-style: italic;
	letter-spacing: 0.4px;
	text-align: center;
	text-align: left;
	@media(max-width: $sw-xs-max) {
		margin-bottom: 2rem;
	}
}

.p--it {
	font-size: 14px;
	font-style: italic;
	line-height: 2.1em;
	font-weight: 500;
}

.set-up {
	display: flex;
	justify-content: space-between;
	@media(max-width: $sw-sm-max) {
		align-items: center;
	}
	@media(max-width: $sw-xs-max) {
		flex-direction: column;
		justify-content: center;
	}
	&__left {
		width: 60%;
		@media(max-width: $sw-xs-max) {
			order: 2;
			width: 100%;
			.subtitle--red {
				text-align: center;
			}
		}
		p{
			margin-bottom: 3.5rem;
			font-size: 13px;
			color: $black;
			line-height: 1.5em;
			letter-spacing: 0.4px;
			text-align: center;
			text-align: left;
			@media(max-width: $sw-xs-max) {
				margin-bottom: 2rem;
			}
		}
	}
	&__right {
		width: 30%;
		@media(max-width: $sw-xs-max) {
			order: 1;
			margin-bottom: 3rem;
			width: auto;
		}
	}
	p {
		font-size: 1.3rem;
		color: $black;
		font-weight: 300;
	}
}

.service-form {
	padding-top: 5rem;
	border-bottom: 1px solid #b4b4b4;
	.form {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.form-group:not(:nth-child(3)) {
		width: 47%;
		@media(max-width: $sw-xx-max) {
			width: 100%;
		}
	}

	.form-group:nth-child(3) {
		width: 100%;
	}

	.form-control {
		border-bottom: 4px solid #e2e2e2;
		color: $black;
		&::placeholder {
			color: $black;
		}
	}
}

.services-end-text {
	padding: 10rem 0;
	
	@media(max-width: $sw-xs-max) {
		padding: 5rem 0;
	}
	
	h2 {
		margin-bottom: 4rem;
		@media(max-width: $sw-xs-max) {
			margin-bottom: 1rem;
		}
	}

	.p--bl {
		margin-bottom: 3rem;
		@media(max-width: $sw-xs-max) {
			margin-bottom: 1rem;
		}
	}
}