.c-header{
	position: relative;
	z-index: 2;
	transition: background-color 0.5s ease;
	.container {
		max-width: 1524px !important;
	}
	// &.fixed {
	// 	position: fixed;
	// 	width: 100%;
	// 	top: -100%;
	// 	background-color: $white;
	// 	z-index: 5;
	// 	transition: top 1.5s ease;
	// }

	// &.sticky {
	// 	top: 0;
	// 	transition: top 1.5s ease;
	// }
	&.fixed {
		background-color: $white;
		position: fixed;
		z-index: 5;
		right: 0;
		left: 0;
		top: 0;
		border-bottom: 1px solid $gray-border;
		&~main {
			padding-top: 10.4rem;
		}

		.header__wrapper {
			border-bottom: none;
		}
	}
}

.header__wrapper {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2.5rem 0;
	border-bottom: 1px solid $gray-border;
	@media(max-width: $sw-xs-max) {
		padding: 1.5rem 0;
	}
}

.burger-menu button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 5.7rem;
	height: 5.3rem;
	background-color: $black;
	transition: background-color $transition ease;
	@media(max-width: $sw-xs-max) {
		width: 3.5rem;
		height: 3.5rem;
	}
	&:hover {
		background-color: $red;
	}
}

.logo {
	margin-left: 18.5rem;
	a {
		display: block;
	}
	.icon {
		font-size: 5.1rem;
		color: $black;
		@media(max-width: $sw-xs-max) {
			font-size: 3.1rem;
		}
		@media(max-width: $sw-xx-max) {
			font-size: 2.8rem;
		}
	}

	@media(max-width: $sw-sm-max) {
		margin-left: 4rem;
	}

	@media(max-width: $sw-xs-max) {
		margin-left: 4rem;
	}

	@media(max-width: $sw-xx-max) {
		margin-left: 0;
	}

	&-active {
		@media(max-width: $sw-md-max) {
			visibility: hidden;
		}
	}
}

.header__control {
	text-align: center;
	display: flex;
	align-items: stretch;
	.button-contact {
		margin-right: 1.6rem;
		@media(max-width: $sw-xx-max) {
			margin-right: 5px;
			line-height: 1.3em;
			max-width: 7rem;
		}
	}
	.active {
		.input-search {
			right: 4rem;
			opacity: 1;
			pointer-events: auto;
			@media(max-width: $sw-md-max) {
				right: 5rem;
				width: 53rem;
			}
			@media(max-width: $sw-sm-max) {
				width: 42.3rem;
			}
			@media(max-width: 810px) {
				width: 38rem;
			}
			@media(max-width: $sw-xs-max) {
				right: 3rem;
				width: 32rem;
				min-width: auto;
				height: 3.5rem;
			}

			@media(max-width: 630px) {
				width: 24rem;
			}

			@media(max-width: 610px) {
				width: 22rem;
			}
			@media(max-width: 530px) {
				width: 20rem;
			}
			@media(max-width: 515px) {
				width: 18rem;
			}
			@media(max-width: $sw-xx-max) {
				width: 22rem;
			}
			@media(max-width: 400px) {
				width: 19rem;
			}
			@media(max-width: 400px) {
				width: 17rem;
			}
			@media(max-width: 350px) {
				width: 14.2rem;
			}
		}
	}
}

.search-form {
	position: relative;
	margin-right: 2.5rem;
	display: flex;
	align-items: center;
	@media(max-width: $sw-xs-max) {
		margin-right: 1.5rem;
	}
	@media(max-width: $sw-xx-max) {
		margin-right: 5px;
	}
	.input-search {
		position: absolute;
		top: 0;
		right: -3rem;
		z-index: 7;
		padding: 0 5px;
		height: 5.3rem;
		min-width: 20rem;
		border: none;
		border-bottom: 3px solid $red;
		transition: all $transition ease;
		opacity: 0;
		pointer-events: none;
		font-family: 'Roboto';
		font-size: 1.5rem;
		background-color: transparent;
		border-radius: 0;
		@media(max-width: $sw-md-max) {
			right: -10rem;
		}
		@media(max-width: $sw-xs-max) {
			min-width: 18rem;
		}
	}

	.btn-submit {
		position: absolute;
		right: 0;
		display: none;
		width: 2.5rem;
		height: 100%;
	}

	.icon-search {
		cursor: pointer;
		@media(max-width: $sw-xx-max) {
			font-size: 2rem;
		}
	}
}

.search-form.active {
	.btn-submit {
		display: block;
	}
}

.wrapper-dropdown {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: 3.3rem;
	color: $black;
	cursor: pointer;
	font-weight: 700;
	font-size: 1.5rem;
	letter-spacing: 1.5px;
	@media(max-width: $sw-xx-max) {
		display: none;
	}
}

.wrapper-dropdown {
	.dropdown {
		position: absolute;
		top: 80%;
		right: 0;
		display: flex;
		flex-direction: column;
		width: 100%;
		font-weight: normal;
		opacity: 0;
		pointer-events: none;
		list-style: none;
		margin: 0;
		padding: 0;
		text-align: left;
			li {
			a {
				display: block;
				text-decoration: none;
				color: $black;
				font-weight: 500;
				height: 100%;
				transition: color $transition ease;
			}
			&:hover, &.active {
				a {
					color: $red;
				}
			}
		}
	}

	.icon-arrow-down {
		transition: all $transition ease;
	}
}
	
	/* Active state */
	

.wrapper-dropdown.active .dropdown {
	opacity: 1;
	pointer-events: auto;
}

.wrapper-dropdown.active .icon-arrow-down {
	transform: rotate(180deg);
}


.main-menu {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 20%;
	height: 0;
	overflow: hidden;
	transition: height .8s ease;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $red;
		opacity: 0.9;
	}
	&.active {
		display: flex;
		justify-content: center;
		height: 100vh;
		transition: height 0.8s ease;
	}
	&-inner {
		position: relative;
		padding-top: 7px;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
	.logo {
		margin-bottom: 13rem;
		margin-left: 5px;
		@media(max-width: $sw-sm-max) {
			margin-left: -13.7rem;
		}
		@media(max-width: $sw-xs-max) {
			padding-top: 0;
		}
		@media(max-width: $sw-xx-max) {
			margin-left: -6.7rem;
		}
		a {
			@media(max-width: $sw-xs-max) {
				line-height: 1.5em;
			}
		}
		.icon {
			color: $white;
		}
	}
	.btn-close {
		position: absolute;
		top: 2rem;
		right: 2rem;
		color: $white;
		font-size: 1.4rem;
	}

	li {
		width: 45rem;
		@media(max-width: $sw-xs-max) {
			width: auto;
		}
	}
	a {
		position: relative;
		display: block;
		width: 100%;
		text-transform: uppercase;
		color: $white-font;
		font-size: 4rem;
		font-weight: 500;
		line-height: 2em;
		transition: color 0.8s ease;
		@media(max-width: $sw-xs-max) {
			font-size: 3rem;
		}
		&:hover {
			color: $black;
			@media(min-width: $sw-sm-min) {
				span {
					transform: translateX(31.5rem);
					&:before {
						transform: scaleX(1);
					}
				}
			}
		}
		span {
			position: relative;
			display: inline-block;
			transition: transform 0.8s ease;
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				right: 100%;
				display: block;
				width: 31.5rem;
				height: 3px;
				transform-origin: 100% 50%;
				transform: scaleX(0);
				background-color: $black;
				transition: transform 0.8s ease;
			}
		}
	}
}