.proud-of {
	padding: 6rem 0 11rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media(max-width: $sw-xx-max) {
		padding: 6rem 0 6rem;
	}
	.title--black {
		margin-bottom: 6rem;
		@media(max-width: $sw-xx-max) {
			margin-bottom: 3rem;
		}
	}
}


.skills {
	&__wrapp {
		margin-bottom: 3.8rem;
		display: flex;
		justify-content: space-between;
		max-width: 100%;
		@media(max-width: $sw-sm-max) {
			flex-wrap: wrap;
			justify-content: space-around;
		}
		@media(max-width: $sw-xs-max) {
			margin-bottom: 0;
			justify-content: space-between;
		}
	}

	&__item {
		color: $black;
		width: 18%;
		transition: color $transition;
		@media(max-width: $sw-sm-max) {
			width: 30%;
			margin-bottom: 4rem;
		}
		@media(max-width: $sw-xs-max) {
			width: 47%;
			margin-bottom: 4rem;
			letter-spacing: 2px;
			font-size: 1.6rem;
		}
		&:hover {
			color: $red;
			.skills__icon {
				border-color: $red;
			}
		}
	}

	&__icon {
		margin-bottom: 3rem;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 6.8rem;
		height: 6.8rem;
		border: 1px solid $black;
		border-radius: 50%;
		transition: border-color $transition;
		@media(max-width: $sw-xs-max) {
			margin-bottom: 2rem;
		}
		.icon {
			font-size: 4rem;
		}
	}

	&__title {
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 2.5px;
		@media(max-width: $sw-xs-max) {
			letter-spacing: 2.3px;
			font-size: 1.6rem;
		}
	}
}

