.portfolio {
	padding-top: 10.5rem;
	background-color: $white;
	@media(max-width: $sw-xx-max) {
		padding-top: 4.5rem;
	}
	
	&-inner {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	
	h2 {
		margin-bottom: 1.8rem;
	}

	&-filter {
		margin-bottom: 3.5rem;
		
		.filter__button {
			position: relative;
			margin-bottom: 1rem;
			display: inline-block;
			text-decoration: none;
			font-size: 1.4rem;
			text-transform: uppercase;
			color: $black;
			font-weight: 500;
			letter-spacing: 2.8px;
			transition: color $transition;
			cursor: pointer;
			@media(max-width: $sw-xs-max) {
				letter-spacing: 1.3px;
				font-size: 1rem;
			}
		}

		.filter__button::after {
			position: absolute;
			bottom: -1rem;
			left: -1px;
			content: '';
			display: block;
			width: 0;
			height: 4px;
			background: $black;
			transition: width $transition, background $transition;
			@media(max-width: $sw-xs-max) {
				bottom: -0.4rem;
			}
		}

		@media(min-width: $sw-sm-min) {
			.filter__button:hover {
				color: $red;
			}

			.filter__button:hover::after {
				width: 100%;
				background: $red;
			}
		}


		.filter__button:not(:last-child) {
			margin-right: 4rem;
			@media(max-width: $sw-md-max) {
				margin-right: 3rem;
			}
			@media(max-width: $sw-xs-max) {
				margin-right: 1.2rem;
			}
		}

		.is-checked {
			color: $red;
			&::after {
				position: absolute;
				bottom: -1rem;
				left: -1px;
				content: '';
				display: block;
				width: 100%;
				height: 4px;
				background: $red;
				@media(max-width: $sw-xs-max) {
					bottom: -0.4rem;
				}
			}
		}
	}

	&-contant {
		width: 100%;
		max-height: 54rem;
		overflow: hidden;
		@media(max-width: $sw-md-max) {
			max-height: 42.6rem;
		}
		@media(max-width: $sw-sm-max) {
			max-height: 33.4rem;
		}
		@media(max-width: $sw-xs-max) {
			max-height: 40rem;
		}
		@media(max-width: $sw-xs-max) {
			max-height: 31rem;
			// overflow: scroll;
			// overflow: visible;
			max-height: inherit;
		}
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}

	.gutter-sizer {
		width: 3rem;
	}

	.gutter-sizer { 
		width: 30px; 
	}

	&-item {
		position: relative;
		margin-bottom: 3rem;
		// width: 27rem;
		width: calc(25% - 22.5px);
		height: 24rem;
		@media(max-width: $sw-md-max) {
			height: 18.3rem;
		}
		@media(max-width: $sw-sm-max) {
			height: 13.7rem;
		}
		@media(max-width: $sw-xs-max) {
			width: calc(25% - 22.5px);
		}
		@media(max-width: $sw-xx-max) {
			width: calc(50% - 15px);
		}
	&:hover {
			.portfolio-item__links {
				opacity: 1;
				visibility: visible;
				transition: opacity $transition ease, visibility $transition ease;
			}

			.portfolio-item__links-demo {
				margin-bottom: 1.6rem;
			}
		}
		
		&--vr {
			width: calc(25% - 22.5px);
			height: 51rem;
			@media(max-width: $sw-md-max) {
				height: 39.6rem;
			}
			@media(max-width: $sw-sm-max) {
				height: 30.4rem;
			}
			@media(max-width: $sw-xs-max) {
				width: calc(25% - 22.5px);
			}
			@media(max-width: $sw-xx-max) {
				width: calc(50% - 15px);
				height: 25.4rem;
			}
		}
		&--hr {
			width: calc(50% - 15px);
			@media(max-width: $sw-xs-max) {
				width: calc(50% - 15px);
			}
			@media(max-width: $sw-xx-max) {
				height: 10.7rem;
			}
			.portfolio-item__links {
				flex-direction: row;
			}

			&:hover {
				.portfolio-item__links-demo {
					margin-bottom: 0;
					margin-right: 1.6rem;
				}
			}

		}
		img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 100%;
			margin: auto;
			object-fit: cover;
		}
		&__links {
			opacity: 0;
			visibility: hidden;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			transition: opacity $transition ease, visibility $transition ease;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: #313131;
				opacity: 	0.5;
			}
			.portfolio-item__links-demo, .portfolio-item__links-source {
				position: relative;
				// z-index: 1;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 5rem;
				height: 5rem;
				background-color: $white;
				color: $black;
				transition: background-color $transition ease, color $transition ease, margin $transition ease;
			}

			&-demo {
				&:hover {
					background-color: $red;
					color: $white;
				}
			}

			&-source {
				&:hover {
					background-color: $black;
					color: $white;
				}
			}

			.icon-eye {
				font-size: 2rem;
			}
			.icon-unlink {
				font-size: 1.6rem;
			}
		}	
	}

	&-inside {
		padding: 8rem 0 15rem;
		@media(max-width: $sw-xs-max) {
			padding: 3rem 0 5rem;
		}
		&__picture {
			width: 100%;
			position: relative;
            margin-bottom: 7.3rem;
			@media(max-width: $sw-xs-max) {
              margin-bottom: 3rem;
			}
			.h-object-fit {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				width: 100%;
				height: 100%;
			}
			&:before {
				padding-bottom: 51.0711225364%;
				content: ' ';
				visibility: hidden;
				display: block;
			}
			&-btn {
				position: absolute;
				bottom: 0;
				right: 0;
				width: 57px;
				height: 57px;
				background-color: #b51622;

				display: flex;
				align-items: center;
				justify-content: center;
                transition: background-color .5s ease;
				&.ext_link{
					right: 70px;
				}
                @media(max-width: $sw-xx-max) {
                  width: 37px;
                  height: 37px;
                }
				.icon {
					font-size: 25px;
					color: white;
                    @media(max-width: $sw-xx-max) {
                      font-size: 18px;
                    }
				}

                &:hover {
                    background-color: #252525;
                }
			}
		}
		&__back {
			display: flex;
			align-items: flex-start;
			border-bottom: 1px solid #e2e2e2;
            @media(max-width: $sw-xx-max) {
              justify-content: center;
              border: none;
            }
		}
		&__info {
			margin-bottom: 95px;

			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			@media(max-width: $sw-xs-max) {
				margin-bottom: 45px;
                flex-wrap: wrap;
			}
			&-left {
				flex-shrink: 0;
				width: 57%;
                padding-top: 14px;
                @media(max-width: $sw-xs-max) {
                  width: 100%;
                  margin-bottom: 35px;
                }
			}
			&-right {
				flex-shrink: 0;
				width: 36%;
                @media(max-width: $sw-xs-max) {
                  width: 100%;
                }
			}
			&-ttl {
				font-size: 2.4rem;
				font-family: 'Comforta', sans-serif;
				color: #252525;
				letter-spacing: 0.22px;
				padding-bottom: 16px;
                margin-bottom: 31px;
				border-bottom: 1px solid rgba(#b4b4b4, .41);
                @media(max-width: $sw-xs-max) {
                  padding-bottom: 10px;
                  margin-bottom: 24px;
                }
                @media(max-width: $sw-xx-max) {
                  font-size: 2rem;
                }
			}
			&-about {
				margin-bottom: 23px;
				span {
					&:first-child {
						color: #000000;
						font-family: "Oswald";
						font-size: 12px;
						font-weight: 500;
						line-height: 2em;
						text-transform: uppercase;
						letter-spacing: 2.4px;
						display: block;
						text-align: right;
                        margin-bottom: 2px;
                        @media(max-width: $sw-md-max) {
                          margin-bottom: 0;
                        }
                        @media(max-width: $sw-sm-max) {
                          font-size: 10px;
                        }
					}
					&:last-child {
						line-height: 1.2em;
						font-family: 'Comforta', sans-serif;
						font-weight: 700;
						letter-spacing: .33px;
						font-size: 36px;
						color: #000000;
						display: block;
						text-align: right;
                        @media(max-width: $sw-md-max) {
                          font-size: 26px;
                        }
                        @media(max-width: $sw-sm-max) {
                          font-size: 18px;
                        }
					}
				}
			}
			&-sub {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				margin-bottom: 86px;

				@media(max-width: $sw-md-max) {
					margin-bottom: 40px;
				}
				&-line {
					display: flex;
					align-items: center;
					&:not(:last-child) {
						margin-bottom: 25px;
                        @media(max-width: $sw-md-max) {
                          margin-bottom: 5px;
                        }
					}
					span {
						margin-right: 5px;
					}
					span, time {
						color: #000000;
						font-family: "Roboto";
						font-size: 13px;
						font-weight: 300;
						line-height: 1.6em;
						letter-spacing: .33px;
					}
				}
				&-inner {

				}
				&-share {
					display: flex;
					align-items: center;
					a {
						width: 20px;
						height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0 8px;
						&:hover {
							.icon {
								color: #b51622;
							}
						}
						.icon {
							font-size: 18px;
							color: #000000;
							transition: color .3s ease;
							&-be {
								font-size: 22px;
							}
						}
					}
				}

			}
			&-works {
				&-txt {
					color: #000000;
					font-family: 'Comforta', sans-serif;
					font-size: 24px;
					margin-bottom: 30px;
					display: block;
					text-align: right;
                    @media(max-width: $sw-xs-max) {
                      text-align: center;
                    }
                    @media(max-width: $sw-xx-max) {
                      margin-bottom: 20px;
                      font-size: 20px;
                    }
				}
				&-inner {
					display: flex;
					align-items: flex-start;
					justify-content: flex-end;

                    @media(max-width: $sw-md-max) {
                      flex-direction: column;
                      align-items: flex-end;
                      justify-content: flex-start;
                    }
                    @media(max-width: $sw-xs-max) {
                      flex-direction: row;
                      align-items: center;
                      justify-content: center;
                    }
                    @media(max-width: $sw-xx-max) {
                      flex-direction: column;
                    }
				}
				&-item {
					display: flex;
					flex-direction: column;
					align-items: center;
					&:not(:last-child) {
						margin-right: 35px;

                        @media(max-width: $sw-md-max) {
                            margin-right: 0;
                            margin-bottom: 25px;
                        }
                        @media(max-width: $sw-xs-max) {
                          margin-right: 15px;
                          margin-bottom: 0;
                        }
                        @media(max-width: $sw-xx-max) {
                          margin-right: 0;
                          margin-bottom: 15px;
                        }
					}
					&-img {
						width: 92px;
						height: 92px;
						margin-bottom: 13px;
						position: relative;

						.h-object-fit {
							position: absolute;
							top: 0;
							left: 0;
							bottom: 0;
							right: 0;
							width: 100%;
							height: 100%;
						}
						img{
							border-radius: 100px;
						-webkit-border-radius: 100px;
						-moz-border-radius: 100px;
						}
					}
					span {
						max-width: 100px;
						color: #000000;
						font-family: "Roboto";
						font-size: 13px;
						font-weight: 300;
						line-height: 1.4em;
						text-align: center;
						letter-spacing: .33px;
						a{
							color: #000000;
						}
					}
				}
			}
			&-inner {
				display: flex;
				align-items: flex-start;
			}
			p {
				color: #000000;
				font-size: 13px;
				line-height: 1.7em;
				font-weight: 300;
				letter-spacing: .33px;
				&:not(:last-child) {
					margin-bottom: 48px;
                    @media(max-width: $sw-md-max) {
                      margin-bottom: 40px;
                    }
                    @media(max-width: $sw-xs-max) {
                      margin-bottom: 20px;
                    }
				}
			}
		}
	}
}

.back-btn {
  height: 46px;
  line-height: 46px;
  background-color: #000000;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  transition: background-color .5s ease;
    &:hover {
      background-color: #b51622;
    }
	span {
      color: #ffffff;
      font-family: "Oswald";
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 2.4px;
      padding-left: 12px;
    }
    .icon {
      color: white;
      font-size: 12px;
    }
}










