.experience {
	position: relative;
	padding: 9rem 0 10rem;
	background-color: $red;
	@media(max-width: $sw-xs-max) {
		padding: 5rem 0 5rem;
	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: url('../images/useful/bg/about-page.png');
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.5;
	}

	&-inner {
		position: relative;
		z-index: 2;
	}
	h2 {
		margin-bottom: 3rem;
		display: block;
		text-align: center;
		@media(max-width: $sw-xs-max) {
			margin-bottom: 2rem;
		}
	}

	h3 {
		margin-bottom: 2.9rem;
	}

	&__text {
		column-count: 2;
		column-gap: 3rem;
		text-align: left;
		@media(max-width: $sw-xs-max) {
			column-count: 1;
		}
	}
	p{
		margin-bottom: 3.5rem;
	    font-size: 13px;
	    color: #fff;
	    line-height: 1.5em;
	    letter-spacing: 0.4px;
	    text-align: center;
	    text-align: left;
	    @media(max-width: $sw-xs-max) {
			margin-bottom: 2rem;
		}
	}
}