.typography {
	padding-bottom: 150px;
	@media(max-width: $sw-xs-max) {
		padding-bottom: 50px;
	}
	
	&-inner {
		padding: 80px 0 40px;
		border-bottom: 1px solid #e2e2e2;
		@media(max-width: $sw-xs-max) {
			padding: 40px 0 20px;
		}
		&:last-child{
			border-bottom: none;
		}
	}
	p {
		margin-bottom: 40px;
		font-size: 13px;
		// font-weight: 300;
		line-height: 22px;
		color: #000000;
		letter-spacing: 0.33px;
		@media(max-width: $sw-xs-max) {
			margin-bottom: 20px;
		}
	}

	.block-2col {
		column-count: 2;
		column-gap: 3rem;
		margin-bottom: 3rem;
		@media(max-width: $sw-xs-max) {
			column-count: 1;
			margin-bottom: 0;
		}
	}

	.block-list {
		h2 {
			margin-bottom: 2.5rem;
			@media(max-width: $sw-xs-max) {
				margin-bottom: 1rem;
			}
		}

		p {
			margin-bottom: 2.5rem;
		}
		ul {
			margin-bottom: 2.5rem;
		}
	}

	blockquote {
		position: relative;
		padding: 25px 30px 25px 70px;
		margin-bottom: 40px;
		background-color: #f7f7f7;
		font-style: italic;
    font-weight: 500;
    line-height: 22px;
		@media(max-width: $sw-xx-max) {
			padding: 15px 15px 15px 50px;
		}
		&:before {
			content: '';
			position: absolute;
			top: 30px;
			left: 25px;
			display: block;
			width: 3.3rem;
			height: 2.9rem;
			background-image: url(../images/tmp_file/blog-page/blog-quote.png);
			background-size: cover;
			@media(max-width: $sw-xx-max) {
				top: 15px;
				left: 15px;
				width: 2.5rem;
				height: 2.2rem;
			}
		}
		p {
			margin-bottom: 0;
			font-style: italic;
			font-weight: 500;
			line-height: 22px;
		}
	}

	.name-quote {
		text-align: right;
		font-style: italic;
		font-weight: 500;
		line-height: 22px;
	}
	h2 {
		color: #000000;
		font-family: 'Comforta', sans-serif;
		font-size: 24px;
		letter-spacing: 0.22px;
	}
	 li {
		position: relative;
		padding-left: 20px;
		font-size: 13px;
		line-height: 22px;
		color: #000000;
		letter-spacing: 0.33px;
		&:before {
			content: '';
			position: absolute;
			top: calc(50% - 4px);
			left: 1px;
			bottom: 0;
			width: 8px;
			height: 8px;
			background-color: $red;
		}
	 }
}