.modal-get-in-touch {

	.modal-header {
		padding-top: 5rem;
		border-bottom: none;
	}

	.contact__form {
		padding-top: 2.5rem;
		width: 100%;
		min-height: inherit;
		border: none;
	}

	.subtitle--black {
		margin: auto;
	}

	.files {
		flex-direction: column;
		align-items: flex-start;	
	}
	.fileList {
		max-width: inherit;
	}
}

.modal-success {

	.modal-dialog {
		max-width: 40rem;
	}

	.modal-header {
		justify-content: center;
		padding: 3rem 2rem;
	}
	
	.modal-body {
		padding: 3rem 2rem;
		text-align: center;
		p {
			letter-spacing: 2.8px;
		}
	}

	.tick {
		stroke-dasharray: 29px;
		stroke-dashoffset: 29px;
		animation: draw .6s cubic-bezier(.25, .25, .25, 1) forwards;
		animation-delay: 1.2s
	}

	.circle {
			fill-opacity: 0;
			stroke: #219a00;
			stroke-width: 16px;
			transform-origin: center;
			transform: scale(0);
			animation: grow 1s cubic-bezier(.25, .25, .25, 1.25) forwards;   
	}

	@keyframes grow {
			60% {
					transform: scale(.8);
					stroke-width: 4px;
					fill-opacity: 0;
			}
			100% {
					transform: scale(.9);
					stroke-width: 8px;
					fill-opacity: 1;
					fill: #219a00;
			}
	}

	@keyframes draw {
			100% { stroke-dashoffset: 0; }
	}
}

.modal-header {
	position: relative;
	.close {
		position: absolute;
		right: 1rem;
		top: 1rem;
		font-size: 4rem;
		span {
			display: block;
			line-height: 0.4em;
		}
	}
}